import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";

function TourForm() {
    const [files, setFiles] = useState(null);
    const [images, setImages] = useState([]); // List of images with names and Base64 data
    const [imagesName, setImagesName] = useState([]);

    const [formData, setFormData] = useState({
        TourName: '',
        TourImage: '',
        TourPrice: '',
        SoLuongVe: '',
        TourTime: null,  // Sử dụng kiểu dữ liệu Date cho thời gian tour
        TourDay: '',
        TourDetail: '',
        TourIntroduce: '',
        TourVideo: '',
        TouKV: '',
        TouQG: '',
        TouTL: '',
    });

    const [khuvuc, setKV] = useState([]);
    const [quocgia, setQG] = useState([]);
    const [theloaidl, setTL] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST}/api/khuvuc`)
            .then((response) => response.json())
            .then((data) => setKV(data))
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST}/api/quocgia`)
            .then((response) => response.json())
            .then((data) => setQG(data))
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST}/api/theloaidl`)
            .then((response) => response.json())
            .then((data) => setTL(data))
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Lấy file đầu tiên từ input
        if (!file) return;

        setFiles([file]); // Ensure files is an array
        const reader = new FileReader();
        reader.onload = () => {
            setImages([{ name: file.name, base64: reader.result }]); // Chỉ lưu một ảnh
            setImagesName([file.name]); // Cập nhật tên ảnh
            setFormData((prev) => ({
                ...prev,
                TourImage: 'https://s3.tebi.io/image-web-travel/' + file.name, // Đồng bộ vào formData
            }));
        };
        reader.onerror = () => console.error('Error reading file:', reader.error);
        reader.readAsDataURL(file);
    };

    const handleUpload = async (e) => {
        if (!files || files.length === 0) {
            alert("Vui lòng chọn ảnh");
            return;
        }

        const formData = new FormData();
        files.forEach((image) => {
            console.log(image);  // In ra file đang được thêm vào FormData
            formData.append("images", image);  // Thêm từng file vào FormData
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST}/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            const uploadedUrls = response.data.fileUrls; // Lấy URL từ response
            console.log(uploadedUrls); // Log the uploaded URLs if necessary
        } catch (error) {
            console.error("Lỗi khi tải ảnh:", error);
        }
    };



    const handleRemoveImage = () => {
        setImages([]); // Xóa ảnh
        setImagesName([]); // Xóa tên ảnh
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'TouQG') {
            const [idQG, idKV] = value.split('-');
            setFormData({
                ...formData,
                TouQG: idQG,
                TouKV: idKV,
            });
        }
    };

    const handleDateChange = (date) => {
        // Chỉ lấy ngày (YYYY-MM-DD)
        const formattedDate = date.toISOString().split('T')[0];
        setFormData({
            ...formData,
            TourTime: formattedDate,
        });
    };

    const handleSubmitTravel = async (e) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/tourdl`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                alert(result.message);
                setFormData({
                    TourName: '',
                    TourImage: '',
                    TourPrice: '',
                    SoLuongVe: '',
                    TourTime: null,  // Reset thời gian tour
                    TourDay: '',
                    TourDetail: '',
                    TourIntroduce: '',
                    TourVideo: '',
                    TouKV: '',
                    TouQG: '',
                    TouTL: '',
                });
            } else {
                alert(result.error);
            }
        } catch (error) {
            console.error('Lỗi khi gửi dữ liệu:', error);
            alert('Đã xảy ra lỗi khi gửi dữ liệu');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault(); // Ngăn hành vi mặc định của form

        try {
            await handleUpload(e); 
            await handleSubmitTravel(e); 
        } catch (error) {
            console.error('Lỗi khi xử lý submit:', error);
        }
    };
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-6">Thêm Tour Du Lịch</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Tên Tour</label>
                    <input
                        type="text"
                        name="TourName"
                        value={formData.TourName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Hình ảnh Tour</label>
                    <input
                        type="text"
                        name="TourName"
                        value={imagesName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                        readOnly
                    />
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-100 file:text-blue-600 hover:file:bg-blue-200"
                    />
                    <ul className="mt-2 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                        {images.map((image, index) => (
                            <li key={index} className="flex flex-col items-center">
                                <img
                                    src={image.base64}
                                    alt={image.name}
                                    className="w-24 h-24 object-cover rounded-md shadow"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleRemoveImage(index)}
                                    className="mt-2 text-red-500 hover:text-red-700"
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <div>
                    <label className="block mb-1 font-medium text-gray-700">Giá Tour</label>
                    <input
                        type="number"
                        name="TourPrice"
                        value={formData.TourPrice}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Số lượng vé</label>
                    <input
                        type="number"
                        name="SoLuongVe"
                        value={formData.SoLuongVe}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Thời gian Tour</label>
                    <DatePicker
                        selected={formData.TourTime ? new Date(formData.TourTime) : null}
                        onChange={handleDateChange}
                        dateFormat="yyyy/MM/dd"
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholderText="Chọn ngày"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Số ngày Tour</label>
                    <input
                        type="number"
                        name="TourDay"
                        value={formData.TourDay}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Chi tiết Tour</label>
                    <textarea
                        name="TourDetail"
                        value={formData.TourDetail}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Giới thiệu Tour</label>
                    <textarea
                        name="TourIntroduce"
                        value={formData.TourIntroduce}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Video Tour</label>
                    <input
                        type="text"
                        name="TourVideo"
                        value={formData.TourVideo}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Quốc gia - Khu Vực</label>
                    <select
                        name="TouQG"
                        value={`${formData.TouQG}-${formData.TouKV}`}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Chọn quốc gia và khu vực</option>
                        {quocgia.map((qg) => {
                            const region = khuvuc.find((kv) => kv.IdKV === qg.KhuVucId);
                            return (
                                <option key={qg.IdQG} value={`${qg.IdQG}-${qg.KhuVucId}`}>
                                    {qg.TenQG} - {region ? region.TenKV : ''}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div>
                    <label className="block mb-1 font-medium text-gray-700">Loại Tour</label>
                    <select
                        name="TouTL"
                        value={formData.TouTL}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Chọn loại tour</option>
                        {theloaidl.map((tl) => (
                            <option key={tl.IdTL} value={tl.IdTL}>
                                {tl.TenTL}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    type="submit"
                    className="w-full py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                    Thêm Tour
                </button>
            </form>
        </div>

    );
}

export default TourForm;
