import React, { useState, useEffect } from 'react';

function TourList() {
    const [tourData, setTourData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [toursPerPage] = useState(5); // Số lượng tour trên mỗi trang
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTourData();
    }, []);

    // Hàm lấy dữ liệu tour từ API
    const fetchTourData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/tourdl`);
            if (!response.ok) {
                throw new Error('Lỗi khi gọi API');
            }
            const data = await response.json();
            // Sắp xếp dữ liệu theo IdTour giảm dần
            const sortedData = data.sort((a, b) => b.TourId - a.TourId);
            setTourData(sortedData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Hàm cập nhật trạng thái của tour dựa trên TourId
    const handleUpdateStatus = async (TourId) => {
        const confirmUpdate = window.confirm('Bạn có chắc chắn muốn đổi trạng thái của tour này thành off?');
        if (confirmUpdate) {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/api/tourdl/${TourId}`, {
                    method: 'PUT',
                });
                if (!response.ok) {
                    throw new Error('Lỗi khi cập nhật trạng thái tour');
                }
                // Cập nhật thành công, cập nhật lại danh sách tour
                setTourData(tourData.map((tour) =>
                    tour.TourId === TourId ? { ...tour, TrangThai: 'off' } : tour
                ));
                alert('Đã cập nhật trạng thái tour thành công!');
            } catch (err) {
                alert('Đã xảy ra lỗi khi cập nhật trạng thái tour: ' + err.message);
            }
        }
    };


    // Lọc dữ liệu theo từ khóa tìm kiếm
    // Lọc dữ liệu theo từ khóa tìm kiếm và trạng thái 'on'
    const filteredTours = tourData.filter(tour =>
        tour.TrangThai === 'on' && tour.TourName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Lấy dữ liệu tour cho trang hiện tại
    const indexOfLastTour = currentPage * toursPerPage;
    const indexOfFirstTour = indexOfLastTour - toursPerPage;
    const currentTours = filteredTours.slice(indexOfFirstTour, indexOfLastTour);

    // Thay đổi trang
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return <p>Đang tải dữ liệu...</p>;
    }

    if (error) {
        return <p>Có lỗi xảy ra: {error}</p>;
    }

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Danh sách Tour Du Lịch</h2>

            {/* Thanh tìm kiếm */}
            <input
                type="text"
                placeholder="Tìm kiếm tour..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            {/* Bảng dữ liệu */}
            <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 border border-gray-200">ID</th>
                        <th className="px-4 py-2 border border-gray-200">Tên Tour</th>
                        <th className="px-4 py-2 border border-gray-200">Giá Tour</th>
                        <th className="px-4 py-2 border border-gray-200">Thời gian</th>
                        <th className="px-4 py-2 border border-gray-200">Số ngày</th>
                        <th className="px-4 py-2 border border-gray-200">Chi tiết</th>
                        <th className="px-4 py-2 border border-gray-200">Giới thiệu</th>
                        <th className="px-4 py-2 border border-gray-200">Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTours.length > 0 ? (
                        currentTours.map((tour) => (
                            <tr key={tour.TourId} className="hover:bg-gray-50">
                                <td className="px-4 py-2 border border-gray-200">{tour.TourId}</td>
                                <td className="px-4 py-2 border border-gray-200">{tour.TourName}</td>
                                <td className="px-4 py-2 border border-gray-200">{tour.TourPrice}</td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {new Date(tour.TourTime).toLocaleDateString('vi-VN')}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">{tour.TourDay}</td>
                                <td className="px-4 py-2 border border-gray-200">{tour.TourDetail}</td>
                                <td className="px-4 py-2 border border-gray-200">{tour.TourIntroduce}</td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {/* Nút Xóa */}
                                    <button
                                        onClick={() => handleUpdateStatus(tour.TourId)}
                                        className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8" className="px-4 py-2 text-center border border-gray-200">
                                Không có dữ liệu
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Phân trang */}
            <nav className="mt-4">
                <ul className="flex justify-center space-x-2">
                    {Array.from({ length: Math.ceil(filteredTours.length / toursPerPage) }, (_, i) => (
                        <li key={i + 1}>
                            <button
                                onClick={() => paginate(i + 1)}
                                className={`px-3 py-1 border rounded-md ${i + 1 === currentPage
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-100'
                                    }`}
                            >
                                {i + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>

    );
}

export default TourList;
