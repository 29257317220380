import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ApiContext from '../api/ApiContext';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { CiLinkedin } from "react-icons/ci";
import { FaInstagramSquare } from "react-icons/fa";

function BlogProfile() {
    const { id } = useParams(); // Lấy id từ địa chỉ URL
    const { posts, accounts, khachhangs } = useContext(ApiContext); // Lấy dữ liệu từ Context
    const [postuser, setPost] = useState(null);
    const [account, setAccount] = useState(null);
    const [kh, setKhachHang] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(3); // Số bài viết hiển thị ban đầu
    // Hiển thị các bài viết hiện tại

    useEffect(() => {
        if (!posts || !accounts || !khachhangs) return; // Chờ dữ liệu sẵn sàng
        setKhachHang(khachhangs.TenKH)
        const foundPost = posts.find((blog) => blog.AccountId == id);
        const post = posts.filter((blog) => blog.AccountId == id);

        if (foundPost) {
            const foundAccount = accounts.find((acc) => acc.ID == foundPost.AccountId);
            const foundKhachhang = khachhangs.find((kh) => kh.AccountID == foundPost.AccountId);
            setKhachHang(foundKhachhang)
            setPost(post);
            setAccount(foundAccount);
        }
    }, [posts, accounts, id, khachhangs]);

    if (!postuser) {
        return <div>Đang tải bài viết...</div>;
    }

    if (!account) {
        return <div>Không tìm thấy thông tin tác giả...</div>;
    } const currentPosts = postuser.slice(0, itemsToShow);
    return (
        <div className='w-full h-full flex flex-col py-20 px-2 lg:p-24 gap-y-16 overflow-hidden'>
            <div className=' border-2 bg-orange-50 border-orange-500 shadow-xl rounded-xl p-8 flex flex-col justify-center items-center gap-y-4 fade-in'>
                <div className='flex justify-center items-center fade-in' style={{ animationDelay: '0.3s' }}>
                    <img className="aspect-square w-16 h-16 rounded-full object-cover mr-4 scale-rotate"
                        src={account.ProfilePicture}
                        alt='description' />
                    <div>
                        <div className='font-semibold text-xl'>{kh.TenKH}</div>
                        <div>Thành viên</div>
                    </div>
                </div>
                <div className='text-center px-4 lg:px-40 fade-in' >
                    {kh.NoiDung}
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 my-2'>
                    <a href={kh.Facebook}
                        target="_blank"
                        rel="noopener noreferrer" className='aspect-square text-blue-500 bg-white hover:bg-blue-500 hover:text-white border-2 rounded-xl flex items-center justify-center'>
                        <div className='scale-[2]'><FaFacebookF /></div>
                    </a>
                    <a href={kh.Twitter}
                        target="_blank"
                        rel="noopener noreferrer" className='aspect-square p-4  hover:bg-black bg-white hover:text-white border-2 rounded-xl flex items-center justify-center'>
                        <div className='scale-[2]'><FaXTwitter /></div>
                    </a>
                    <a href={kh.Instagram}
                        target="_blank"
                        rel="noopener noreferrer" className="group aspect-square border-2 rounded-xl bg-white flex flex-col items-center justify-center hover:bg-gradient-to-r hover:from-pink-500 hover:via-red-500 hover:to-yellow-500">
                        <div className="scale-[2] flex items-center">
                            <div className='scale-[1]'><FaInstagramSquare /></div>
                        </div>
                    </a>
                    <a href={kh.LinkedIn}
                        target="_blank"
                        rel="noopener noreferrer" className='aspect-square hover:bg-blue-500 bg-white text-blue-500 hover:text-white  border-2 rounded-xl flex items-center justify-center'>
                        <div className='scale-[2]'><CiLinkedin /></div>
                    </a>
                </div>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4'>
                {currentPosts.map(post => (
                    <a
                        href={`/detailblog/${post.Id}`}
                        className="h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group"
                    >
                        <div className="h-full">
                            <div className="rounded-t-xl overflow-hidden">
                                <img
                                    className="rounded-t-xl aspect-video object-cover transform transition-transform duration-500 group-hover:scale-110"
                                    src={post.image_path} alt="Alaska, Westminster to Greenwich River Thames"
                                />
                            </div>
                            <div className="p-4 flex flex-col justify-between">
                                <div className="">Date: {new Date(post.ngaydang).toLocaleDateString('vi-VN')}</div>
                                <div className="font-bold text-base">
                                    {post.tieude}
                                </div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="h-[1px] bg-gray-300 my-2"></div>
                            <div className="relative w-auto overflow-hidden">
                                <a
                                    href="#"
                                    className="text-sm group-hover:underline transition-all"
                                >
                                    Read More
                                </a>
                                <div className="inset-y-0 left-0 bg-orange-500 w-0 h-[3px] group-hover:w-full transition-all duration-500 ease-in-out"></div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            {itemsToShow < postuser.length && (
                <button
                    className="w-full bg-white rounded-2xl my-6 border border-orange-400 text-center text-orange-400 py-2 hover:bg-orange-400 hover:text-white"
                    onClick={() => setItemsToShow(itemsToShow + 2)}
                >
                    Tải thêm
                </button>
            )}
        </div>
    );
}
export default BlogProfile;
