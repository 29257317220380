import React, { useState, useEffect } from 'react';
import styles from '../css/Signin.module.css';
import CryptoJS from 'crypto-js';
import '../css/global.css';  // Importing the global CSS
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Login({ onLogin }) {
    const [isActive, setIsActive] = useState(false);
    const [formData, setFormData] = useState({ AccountName: "", AccountPassword: "", Mail: "" });
    const [message, setMessage] = useState(""); // To store messages from API
    const navigate = useNavigate();  // Sử dụng useNavigate

    const handleRegisterClick = () => {
        setIsActive(true);
    };

    const handleLoginClick = async () => {
        setIsActive(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/account`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (response.ok) {
                handleLoginClick();
                setMessage("Đăng ký thành công");
            } else {
                setMessage(data.error || "Có lỗi xảy ra");
            }
        } catch (error) {
            console.error("Lỗi khi gửi dữ liệu:", error);
            setMessage("Lỗi khi gửi dữ liệu");
        }
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            localStorage.setItem('token', data.token);
            if (response.ok) {
                if (data.user) {
                    // Cập nhật state userID bằng data.user.ID
                    const userId = data.user.ID;
                    console.log(data.user.ID);
                    sessionStorage.setItem('userId', userId);
                    // Cập nhật trạng thái thành 'on' sau khi đăng nhập thành công
                    await fetch(`${process.env.REACT_APP_HOST}/api/account/${data.user.ID}/trangthai`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ TrangThai: "on" }),
                    });

                    onLogin(); // Call onLogin prop
                    navigate("/profile/account-general"); // Redirect to profile page
                    setMessage("Đăng nhập thành công");
                } else {
                    console.error('User data is missing in response:', data);
                }
            } else {
                setMessage(data.error || "Có lỗi xảy ra");
            }
        } catch (error) {
            console.error("Lỗi khi gửi dữ liệu:", error);
            setMessage("Lỗi khi gửi dữ liệu");
        }
    };

    return (
        <div className="flex justify-center items-center p-4 sm:p-8 lg:p-20">
            <div className={`${styles.container} ${isActive ? styles.active : ''}`} id="container">
                <div className={`${styles['form-container']} ${styles['sign-up']} w-full max-w-md mx-auto`}>
                    <form onSubmit={handleSubmit}>
                        <h1 className="text-2xl font-semibold text-center mb-4">Tạo tài khoản</h1>
                        <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 my-2'>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaFacebookF /></a>
                            </div>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaGithub /></a>
                            </div>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaGoogle /></a>
                            </div>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaXTwitter /></a>
                            </div>
                        </div>
                        <span className="block text-center mt-2">hoặc tự thiết lập tài khoản</span>
                        <input
                            type="text"
                            name="Mail"
                            value={formData.Mail}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();  // Ngăn việc nhập khoảng cách
                                }
                            }}
                            placeholder="Mail"
                            className="w-full p-3 mt-2 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <input
                            type="text"
                            name="AccountName"
                            value={formData.AccountName}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();  // Ngăn việc nhập khoảng cách
                                }
                            }}
                            placeholder="Tên đăng nhập"
                            className="w-full p-3 mt-2 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <input
                            type="password"
                            name="AccountPassword"
                            value={formData.AccountPassword}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();  // Ngăn việc nhập khoảng cách
                                }
                            }}
                            placeholder="Mật khẩu"
                            className="w-full p-3 mt-2 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            type="submit"
                            className="w-full py-3 mt-4 text-white bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none"
                        >
                            Đăng Ký
                        </button>
                        {message && <p className="mt-2 text-center text-red-500">{message}</p>}
                    </form>
                </div>

                <div className={`${styles['form-container']} ${styles['sign-in']} w-full max-w-md mx-auto`}>
                    <form onSubmit={handleSignIn}>
                        <h1 className="text-2xl font-semibold text-center mb-4">Đăng Nhập</h1>
                        <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 my-2'>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaFacebookF /></a>
                            </div>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaGithub /></a>
                            </div>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaGoogle /></a>
                            </div>
                            <div className='aspect-square border-2 rounded-xl flex items-center justify-center'>
                                <a href='/google' className='scale-[1.5]'><FaXTwitter /></a>
                            </div>
                        </div>
                        <span className="block text-center mt-2">hoặc sử dụng tài khoản của bạn</span>
                        <input
                            type="text"
                            name="AccountName"
                            value={formData.AccountName}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();  // Ngăn việc nhập khoảng cách
                                }
                            }}
                            placeholder="Tên tài khoản"
                            className="w-full p-3 mt-2 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <input
                            type="password"
                            name="AccountPassword"
                            value={formData.AccountPassword}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();  // Ngăn việc nhập khoảng cách
                                }
                            }}
                            placeholder="Mật khẩu"
                            className="w-full p-3 mt-2 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <a href="#" className="block text-center text-blue-600 mt-2">Bạn quên mật khẩu?</a>
                        <button
                            type="submit"
                            className="w-full py-3 mt-4 text-white bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none"
                        >
                            Đăng Nhập
                        </button>
                        {message && <p className="mt-2 text-center text-red-500">{message}</p>}
                    </form>
                </div>

                <div className={styles['toggle-container']}>
                    <div className={styles.toggle}>
                        <div className={`${styles['toggle-panel']} ${styles['toggle-left']} text-center`}>
                            <h1 className="text-2xl font-semibold">Chào mừng!</h1>
                            <p className="text-gray-600">Nhận thông tin bạn để sử dụng các tín năng trang web</p>
                            <button className={styles.hidden} id="login" onClick={handleLoginClick}>Đăng Nhập</button>
                            <Link to="/"><button className={styles.hidden}>Trang chủ</button></Link>
                        </div>
                        <div className={`${styles['toggle-panel']} ${styles['toggle-right']} text-center`}>
                            <h1 className="text-2xl font-semibold">Chào, Bạn!</h1>
                            <p className="text-gray-600">Đăng nhập để trải nghiệm các tin năng trang web</p>
                            <button className={styles.hidden} id="register" onClick={handleRegisterClick}>Đăng Ký</button>
                            <Link to="/"><button className={styles.hidden}>Trang chủ</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login;
