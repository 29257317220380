import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import NavBar from './component/navBar';
import Login from "./page/Login";
import TravelHome from './page/Home.js';
import Productdetail from "./component/properties/Productdetail";
import ScrollToTop from "./component/ScrollToTop";
import Footer from './page/Footer';
import Profile from './page/Profile';
import { TranslationProvider } from './component/locales/TranslationContext';
import Prodduct from './component/properties/Properties';
import Contact from './page/Contact';
import Blog from './page/Blog';
import Banner from './component/Banner';
import Dashboad from './page/Dashboad';
import Google from './component/login/google';
import BlogProfile from './page/BlogProfile';
import Editor from './tableBlog/addBlog';
import ImageUpload from './tableBlog/ImageUpload';
import DetailBlog from './tableBlog/detailBlog';
import ErrorBoundary from './component/ErrorBoundary';
import SomeComponent from './SomeComponent';
import Error from './page/ErrorPage';
import { ApiProvider } from './api/ApiContext';  // Import TourProvider
import Cart from './page/cart/Cart';
import CarBookingForm from './page/CarCall';
import WeatherApp from './page/WeatherApp';

function Router() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
    if (storedAuth === 'false') {
      setIsAuthenticated(false);
    }

  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false');
    console.log(isAuthenticated);
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
  };



  // Kiểm tra token khi người dùng chuyển trang hoặc không hoạt động
  const checkTokenExpiration = () => {
    const token = localStorage.getItem('token');
    if (token && typeof token === 'string') {
      try {
        const decodedToken = jwtDecode(token); // Giải mã token
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp < currentTime) {
          handleLogout();
          localStorage.removeItem('token');
          window.location.href = '/login'; // Redirect to login
        }
      } catch (error) {
        console.error("Lỗi khi giải mã token:", error);
      }
    }


    // Kiểm tra nếu token hết hạn

  };
  // Kiểm tra token hết hạn định kỳ
  setInterval(checkTokenExpiration, .1 * 60 * 1000); // Mỗi 5 phút

  let logoutTimer;
  const timeoutDuration = 15 * 60 * 1000; // 15 phút không hoạt động
  const resetTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      handleLogout();
      localStorage.removeItem('token');
      window.location.href = '/login'; // Redirect to login
    }, timeoutDuration);
  };
  // Theo dõi các hoạt động của người dùng
  document.addEventListener('mousemove', resetTimer);
  document.addEventListener('keydown', resetTimer);


  return (
    <TranslationProvider>
      <BrowserRouter>
        <RouterContent
          isAuthenticated={isAuthenticated}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
      </BrowserRouter>
    </TranslationProvider>
  );
}

function RouterContent({ isAuthenticated, onLogin, onLogout }) {
  const location = useLocation(); // This will now work correctly since it's inside BrowserRouter
  return (
    <>
      <ErrorBoundary> {/* Bọc toàn bộ phần bên trong ứng dụng */}
        <ApiProvider>  {/* Bọc ứng dụng với TourProvider */}
          <NavBar isAuthenticated={isAuthenticated} onLogout={onLogout} />
          <Routes>
            <Route path="/" element={<TravelHome />} />
            <Route path="/properties" element={<div><Banner /><Prodduct /></div>} />
            <Route path="/propertiesdetail/:id" element={<Productdetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogprofile" element={<BlogProfile />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/detailblog/:id" element={<DetailBlog />} />
            <Route path="/profile/*" element={!isAuthenticated ? <Error /> : <Profile />} />
            <Route path="/login" element={<Login onLogin={onLogin} />} />
            <Route path="/dashboard" element={<Dashboad />} />
            <Route path="/google" element={<Google onLogin={onLogin} />} />
            {/* <Route path="/error" element={<Error />} /> */}
            <Route path="/some" element={<SomeComponent />} />
            <Route path="/blogprofile/:id" element={<BlogProfile />} />
            <Route path="/weather" element={<WeatherApp />} />
            <Route path="/carcall" element={<CarBookingForm />} />
          </Routes>
          {/* Render Footer only if the current path is not /contact */}
          {location.pathname !== '/contact' && <Footer />}
          <ScrollToTop />
        </ApiProvider>
      </ErrorBoundary>
    </>
  );
};

export default Router;
