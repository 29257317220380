import React, { useState, useEffect } from 'react';

function KhuVucForm() {
    const [khuvucData, setKhuVucData] = useState([]);
    const [newKhuVuc, setNewKhuVuc] = useState(''); // Dùng để lưu dữ liệu khu vực mới
    const [message, setMessage] = useState(''); // Thông báo sau khi thêm khu vực

    // Lấy dữ liệu từ API khi component được mount
    useEffect(() => {
        fetchKhuVucData();
    }, []);

    // Hàm lấy dữ liệu từ API
    const fetchKhuVucData = () => {
        fetch(`${process.env.REACT_APP_HOST}/api/khuvuc`)
            .then((response) => response.json())
            .then((data) => setKhuVucData(data))
            .catch((error) => console.error("Lỗi khi tải dữ liệu:", error));
    };

    // Hàm thêm khu vực mới
    const handleAddKhuVuc = async (e) => {
        e.preventDefault();

        if (!newKhuVuc) {
            setMessage("Tên khu vực không được bỏ trống");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/khuvuc`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ TenKV: newKhuVuc }),
            });

            const result = await response.json();

            if (response.ok) {
                setMessage(result.message);
                setNewKhuVuc(''); // Clear form input
                fetchKhuVucData(); // Cập nhật danh sách khu vực sau khi thêm
            } else {
                setMessage(result.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm dữ liệu:', error);
            setMessage('Đã xảy ra lỗi khi thêm khu vực.');
        }
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Danh sách Khu Vực</h2>

            {/* Form thêm khu vực mới */}
            <form onSubmit={handleAddKhuVuc} className="mb-4">
                <div className="mb-4">
                    <label htmlFor="newKhuVuc" className="block text-sm font-medium text-gray-700">Thêm Khu Vực Mới</label>
                    <input
                        type="text"
                        id="newKhuVuc"
                        value={newKhuVuc}
                        onChange={(e) => setNewKhuVuc(e.target.value)}
                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Nhập tên khu vực"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                    Thêm Khu Vực
                </button>
            </form>

            {/* Hiển thị thông báo sau khi thêm */}
            {message && <p className="text-center text-red-500 mb-4">{message}</p>}

            {/* Bảng hiển thị danh sách khu vực */}
            <table className="min-w-full table-auto border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="py-2 px-4 border-b text-left">ID Khu Vực</th>
                        <th className="py-2 px-4 border-b text-left">Tên Khu Vực</th>
                    </tr>
                </thead>
                <tbody>
                    {khuvucData.length > 0 ? (
                        khuvucData.map((kv) => (
                            <tr key={kv.IdKV}>
                                <td className="py-2 px-4 border-b">{kv.IdKV}</td>
                                <td className="py-2 px-4 border-b">{kv.TenKV}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2" className="py-2 px-4 text-center">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default KhuVucForm;
