import styles from "../css/Navbar.module.css";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import classNames from "classnames";

function Banner() {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState("");
    const [namePage, setNamePage] = useState("");

    useEffect(() => {
        setActiveLink(location.pathname);
        if (location.pathname === "/") {
            setNamePage("Trang Chủ");
        } else if (location.pathname === "/properties") {
            setNamePage("Du Lịch");
        } else if (location.pathname === "/contact") {
            setNamePage("Liên Hệ");
        } else if (location.pathname === "/blog") {
            setNamePage("Mạng Xã Hội");
        } else {
            setNamePage("Unknown");
        }
    }, [location]);

    return (
        <div className={styles.container1}>
            <div className={classNames(styles.backgroudimage)}>
                <div>{namePage}</div>
            </div>
        </div>
    );
}

export default Banner;
