import Home1 from '../component/home/home1'
import Home2 from '../component/home/home2'
import Home3 from '../component/home/home3'
import Home4 from '../component/home/home4'
import Home5 from '../component/home/home5'

function Home() {
  return (
    <div className='overflow-hidden'>
      <Home1></Home1>
      <Home2></Home2>
      <Home3></Home3>
      <Home4></Home4>
      <Home5></Home5>
    </div>

  );
}
export default Home;
