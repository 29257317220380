import React, { useState, useContext, useEffect } from "react";
import TourForm from "../tableTour/TourForm";
import TourList from "../tableTour/TourList";
import KhuVucForm from "../tableKV/KhuVucForm";
import QuocGiaForm from "../tableQG/QuocGiaForm";
import ApiContext from "../api/ApiContext";
import RevenueChartDay from "../Dashboard/RevenueChartDay";
import RevenueChartYear from "../Dashboard/RevenueChartYear";
import PieChart from "../Dashboard/Pie";
import TicketInfo from "../Dashboard/TicketInfo";

const chartMenu = [
    { id: "chartPie", label: "Biểu đồ tròn" },
    { id: "chartTicketInfo", label: "Thống kế số lượng vé" },
    { id: "chartRevenueChartDay", label: "Doanh thu theo ngày" },
    { id: "chartRevenueChartYear", label: "Doanh thu theo năm" },
];

const managerMenu = [
    { id: "account-manager1", label: "Dữ liệu khu vực" },
    { id: "account-manager2", label: "Dữ liệu quốc gia" },
    { id: "account-manager3", label: "Dữ liệu tour du lịch" },
];

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState("chartPie");
    const [showForm, setShowForm] = useState(false);
    const [role, setRole] = useState("");
    const { accountData } = useContext(ApiContext);

    useEffect(() => {
        if (accountData) {
            setRole(accountData.Role);
        }
    }, [accountData]);

    const renderContent = () => {
        switch (selectedTab) {
            case "chartPie":
                return <PieChart />;
            case "chartRevenueChartDay":
                return <RevenueChartDay />;
            case "chartRevenueChartYear":
                return <RevenueChartYear />;
            case "chartTicketInfo":
                return <TicketInfo />;
            case "account-manager1":
                return <KhuVucForm />;
            case "account-manager2":
                return <QuocGiaForm />;
            case "account-manager3":
                return (
                    <div className="relative flex flex-col items-center bg-gray-50 p-5 rounded-lg shadow">
                        <button
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            onClick={() => setShowForm(!showForm)}
                        >
                            Thêm tour
                        </button>
                        <TourList />
                        {showForm && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                <div className="bg-white p-6 rounded shadow-md w-[1000px] max-h-[90vh] overflow-y-auto">
                                    <TourForm />
                                    <button
                                        className="mt-4 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                                        onClick={() => setShowForm(false)}
                                    >
                                        Đóng
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            default:
                return <div>Nội dung không hợp lệ</div>;
        }
    };

    return (
        <div className="container mx-auto p-6 lg:py-20">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row">
                    {/* Sidebar */}
                    <div className="w-full md:w-1/4 p-4 bg-gray-100 space-y-6">
                        {role === "admin" && (
                            <>
                                {/* Chart Menu */}
                                <div>
                                    <h3 className="text-sm font-bold text-gray-600">Biểu đồ</h3>
                                    {chartMenu.map((item) => (
                                        <button
                                            key={item.id}
                                            onClick={() => setSelectedTab(item.id)}
                                            className={`block w-full text-left py-2 px-4 rounded-md ${selectedTab === item.id
                                                ? "bg-blue-200"
                                                : "hover:bg-gray-200"
                                                }`}
                                        >
                                            {item.label}
                                        </button>
                                    ))}
                                </div>

                                {/* Manager Menu */}
                                <div>
                                    <h3 className="text-sm font-bold text-gray-600">Quản lý</h3>
                                    {managerMenu.map((item) => (
                                        <button
                                            key={item.id}
                                            onClick={() => setSelectedTab(item.id)}
                                            className={`block w-full text-left py-2 px-4 rounded-md ${selectedTab === item.id
                                                ? "bg-blue-200"
                                                : "hover:bg-gray-200"
                                                }`}
                                        >
                                            {item.label}
                                        </button>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>

                    {/* Main Content */}
                    <div className="w-full md:w-3/4 p-4">
                        <div className="p-4 bg-gray-50 rounded-lg shadow">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
