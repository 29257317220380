import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RevenueChart = () => {
    const [weeklyRevenueDetails, setWeeklyRevenueDetails] = useState([]); // Doanh thu theo từng ngày trong tuần

    useEffect(() => {
        // Fetch Weekly Data
        fetch(`${process.env.REACT_APP_HOST}/revenue/weekly`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Kiểm tra dữ liệu trả về
                if (Array.isArray(data.revenue)) {
                    const sortedData = data.revenue.sort((a, b) => a.day_of_week - b.day_of_week);
                    const rearrangedData = new Array(7).fill({ daily_revenue: 0 });
                    sortedData.forEach(item => {
                        rearrangedData[item.day_of_week - 1] = item;
                    });
                    setWeeklyRevenueDetails(rearrangedData);
                } else {
                    setWeeklyRevenueDetails([]);
                }
            });
    }, []);

    // Weekly Revenue Chart Data
    const data = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
            {
                label: 'Weekly Revenue',
                data: weeklyRevenueDetails.map(item => item.daily_revenue || 0),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
            {/* Weekly Revenue Section */}
            <div className="mb-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Doanh thu theo ngày</h2>
                <div className="bg-white p-4 rounded-lg shadow">
                    <Bar data={data} />
                </div>
            </div> 
        </div>
    );
};

export default RevenueChart;
