
import React, { useState, useContext, useEffect } from 'react';
import StarRating from './StarRating';
import { TranslationContext } from '../locales/TranslationContext';
import Category from './Categorys';
import { Link } from 'react-router-dom';
import ApiContext from '../../api/ApiContext';

function Product() {
    var productRating = 3;
    const { translations } = useContext(TranslationContext);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };
    const [IdTL, setSelectedMaL] = useState([]);
    const [IdQG, setSelectedIdQG] = useState(0);
    const [sortOrder, setSortOrder] = useState("desc"); // Trạng thái sắp xếp ban đầu
    const [itemsToShow, setItemsToShow] = useState(1); // Hiển thị 4 sản phẩm ban đầu
    const [searchTerm, setSearchTerm] = useState('');

    const { products } = useContext(ApiContext);
    const { quocgia } = useContext(ApiContext);
    const { theloaidl } = useContext(ApiContext);
    const { posts } = useContext(ApiContext);

    // Sắp xếp danh sách sản phẩm dựa trên sortOrder
    const sortProducts = (products) => {
        return products.sort((a, b) => {
            if (sortOrder === "asc") {
                return a.TourId - b.TourId;
            } else {
                return b.TourId - a.TourId;
            }
        });
    };
    const filteredProducts = products.filter((product) => {
        // Tìm kiếm theo tên
        if (!product.TourName.toLowerCase().includes(searchTerm.toLowerCase())) {
            return false;
        }

        // Kiểm tra nếu ngày của sản phẩm nằm trong khoảng thời gian
        const productDate = new Date(product.TourTime);
        if (
            (startDate && productDate < new Date(startDate)) ||
            (endDate && productDate > new Date(endDate))
        ) {
            return false;
        }

        // Nếu không có thể loại nào được chọn, hiển thị tất cả
        if (IdTL.length === 0 && IdQG === 0) {
            return true;
        }

        // Hiển thị sản phẩm nếu thuộc danh sách thể loại được chọn
        if (IdTL.includes(product.TouTL) || (IdQG && product.TouQG === IdQG)) {
            return true;
        }

        return false;
    });

    const filteredSortProducts = sortProducts(filteredProducts)
    // Hiển thị các sản phẩm từ đầu đến itemsToShow
    const currentItems = filteredSortProducts.slice(0, itemsToShow);

    const handleCheckboxChange = (id) => {
        setSelectedMaL((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id) // Bỏ nếu đã chọn
                : [...prevSelected, id] // Thêm nếu chưa chọn
        );
        setItemsToShow(1);
    };

    const handleDropdownItemClickQG = (IdQG) => {
        setSelectedIdQG(IdQG);
    };
    const handleLoadMore = () => {
        setItemsToShow(itemsToShow + 1); // Mỗi lần bấm thêm sản phẩm
    };
    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value); // Cập nhật trạng thái sắp xếp theo tùy chọn người dùng
    };

    return (
        <div className=''>
            <div className="flex flex-col p-4 pt-12 md:p-16 w-full overflow-hidden bg-orange-50">
                <div className="flex flex-col md:flex-row md:h-full space-y-4 md:space-y-0 md:space-x-4">
                    <div className="w-full flex gap-x-4">
                        <div className='flex items-center justify-center'>
                            <div className="mr-4">Sort by:</div>
                            <select className="p-2" value={sortOrder} onChange={handleSortOrderChange}>
                                <option value="asc">Ngày đăng cũ nhất</option>
                                <option value="desc">Ngày đăng mới nhất</option>
                            </select>
                        </div>
                        <div className='flex items-center justify-center'>
                            <div className="mr-4">Search:</div>
                            <input
                                type="text"
                                placeholder="Tìm kiếm tour..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="p-2"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                    {/* Sidebar */}
                    <div className="w-full md:w-1/3 flex flex-col items-center space-y-4">
                        {/* Availability Section */}
                        <div className="bg-white w-full rounded-sm shadow-md p-4">
                            <div className="font-semibold text-lg mb-4">Availability</div>
                            <div className="h-[1px] bg-gray-400 mb-4"></div>
                            <div className="flex flex-col p-6">
                                <span className="font-semibold">From</span>
                                <input
                                    id="datepicker-range-start"
                                    type="date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    className="bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5 mt-2"
                                />
                                <span className="font-semibold pt-4">To</span>
                                <input
                                    id="datepicker-range-end"
                                    type="date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    className="bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5 mt-2"
                                />
                            </div>
                        </div>

                        {/* Theme Section */}
                        <div className="bg-white w-full rounded-sm shadow-md p-4">
                            <div className="font-semibold">Category</div>
                            <div className="h-[1px] bg-gray-400 my-4"></div>
                            <div className="grid p-6 gap-y-2">
                                {theloaidl.map((theloai) => (
                                    <label className="flex items-center" key={theloai.IdTL}>
                                        <input
                                            type="checkbox"
                                            className="form-checkbox text-blue-500 h-5 w-5"
                                            checked={IdTL.includes(theloai.IdTL)}
                                            onChange={() => handleCheckboxChange(theloai.IdTL)}
                                        />
                                        <span className="ml-2 text-gray-700">{theloai.TenTL}</span>
                                    </label>
                                ))}

                            </div>
                        </div>
                    </div>
                    <div className='p-4'></div>
                    {/* Main Content */}
                    <div className="w-full md:w-2/3">
                        <div className="grid grid-flow-row gap-4 ">
                            {currentItems.map((product) => (
                                <a href={`/propertiesdetail/${product.TourId}`}>
                                    <div className="flex bg-white rounded-md p-4 items-center justify-center hover:bg-orange-100">
                                        <div className='w-32 h-32 mx-auto'>
                                            <img className="object-cover rounded-md w-full h-full" src={product.TourImage} alt="description" />
                                        </div>
                                        <div className="w-[90%] grid grid-flow-row py-2 max-md:gap-1 lg:gap-4 pl-4">
                                            <div className="flex items-center max-md:flex-col max-md:items-start">
                                                <div className="bg-orange-300 text-white rounded-lg px-4 mr-2"> {theloaidl.find((theloai) => theloai.IdTL === product.TouTL)
                                                    ?.TenTL || "Thể loại không xác định"}</div>
                                                <div className='flex max-md:py-1'>
                                                    <StarRating rating={productRating} />
                                                    <div className="px-2 text-center max-sm:text-[9.5px]">{`(454 Reviews)`}</div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between max-md:flex-col max-md:items-start">
                                                <div className="font-semibold max-sm:text-sm sm:text-xl md:text-base lg:text-lg xl:text-xl ">{product.TourName}</div>
                                                <div className="font-semibold max-sm:text-base sm:text-xl md:text-base lg:text-lg xl:text-xl text-orange-400 ">${product.TourPrice}</div>
                                            </div>
                                            <div className=''>Date: {new Date(product.TourTime).toLocaleDateString('vi-VN')}</div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                            {/* Add more activity items here */}
                        </div>
                        {itemsToShow < filteredProducts.length && (
                            <button onClick={handleLoadMore} className='w-full bg-white rounded-2xl my-6 border 
                    border-orange-400 text-center text-orange-400 py-2
                    hover:bg-orange-400 hover:text-white '>Load More</button>
                        )}

                    </div>
                </div>
            </div>
            <Category />
            <div className='p-4 md:p-16 w-full overflow-hidden'>
                <div className='font-bold text-lg md:text-3xl py-4'>Article</div>
                {/* Responsive grid for articles */}
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
                    {posts.slice(0, 4).map(post => (
                        <a
                            href={`/detailblog/${post.Id}`}
                            className="h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group"
                        >
                            <div className="h-full">
                                <div className="rounded-t-xl overflow-hidden">
                                    <img
                                        className="rounded-t-xl aspect-video object-cover transform transition-transform duration-500 group-hover:scale-110"
                                        src={post.image_path} alt="Alaska, Westminster to Greenwich River Thames"
                                    />
                                </div>
                                <div className="p-4 flex flex-col justify-between">
                                    <div className="">Date: {new Date(post.ngaydang).toLocaleDateString('vi-VN')}</div>
                                    <div className="font-bold text-base">
                                        {post.tieude}
                                    </div>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="h-[1px] bg-gray-300 my-2"></div>
                                <div className="relative w-auto overflow-hidden">
                                    <a
                                        href="#"
                                        className="text-sm group-hover:underline transition-all"
                                    >
                                        Read More
                                    </a>
                                    <div className="inset-y-0 left-0 bg-orange-500 w-0 h-[3px] group-hover:w-full transition-all duration-500 ease-in-out"></div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                <a href='/blog'>
                    <div className='w-full bg-white rounded-2xl my-6 border 
                    border-orange-400 text-center text-orange-400 py-2
                    hover:bg-orange-400 hover:text-white '>Load More</div>
                </a>
            </div>
        </div>
    );
}
export default Product;
