import React, { useState } from 'react';

const CheckoutForm = ({ cartItems, idKH, name, diachi, sdt, DonHangID = 42 }) => {
    const [formData] = useState({
        KhachHangID: idKH,
        GioHangID: idKH
    });
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.KhachHangID || !formData.GioHangID) {
            setMessage('Vui lòng điền đầy đủ thông tin');
            return;
        }

        // Gửi yêu cầu POST để tạo đơn hàng
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/tao-don-hang`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                const DonHangID = data.DonHangID;

                // Gọi API thêm chi tiết đơn hàng
                const detailsPromises = cartItems.map(item =>
                    fetch(`${process.env.REACT_APP_HOST}/api/them-chitiet-don-hang`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            DonHangID,
                            GioHangID: formData.GioHangID,
                            VeId: item.VeId,
                            SoLuong: item.SoLuongVe,
                            GiaVe: (item.GiaVe * item.SoLuongVe)
                        })
                    })
                );

                await Promise.all(detailsPromises);
                setMessage('Chi tiết đơn hàng đã được thêm thành công');
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Có lỗi xảy ra');
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Thanh toán</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex flex-col">
                    <label className="mb-1 text-sm font-medium">Tên Khách Hàng:</label>
                    <input
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="text"
                        value={name}
                        readOnly
                    />
                </div>
                <div className="flex flex-col">
                    <label className="mb-1 text-sm font-medium">Địa chỉ:</label>
                    <input
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="text"
                        value={diachi}
                        readOnly
                    />
                    <span className="text-xs text-gray-500 mt-1">* Cập nhật địa chỉ trong hồ sơ</span>
                </div>
                <div className="flex flex-col">
                    <label className="mb-1 text-sm font-medium">Số điện thoại:</label>
                    <input
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        type="text"
                        value={sdt}
                        readOnly
                    />
                    <span className="text-xs text-gray-500 mt-1">* Cập số điện thoại trong hồ sơ</span>
                </div>
                <button
                    type="submit"
                    className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                    Tạo Đơn Hàng
                </button>
            </form>
            {message && <p className="mt-4 text-green-600">{message}</p>}
        </div>

    );
};

export default CheckoutForm;
