import React, { useState, useEffect } from 'react';
import CartLike from './CartLike';

const Cart = () => {

    return (
        <div className=''>
            <CartLike />
        </div>
    );
};

export default Cart;
