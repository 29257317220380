import React from 'react';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, Inject, AccumulationTooltip } from '@syncfusion/ej2-react-charts';

const PieChart = () => {
    // Dữ liệu tĩnh cho pie chart
    const pieChartData = [
        { x: 'Category 1', y: 35, text: '35%' },
        { x: 'Category 2', y: 25, text: '25%' },
        { x: 'Category 3', y: 15, text: '15%' },
        { x: 'Category 4', y: 25, text: '25%' },
    ];

    return (
        <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
            <div className="mb-10">
                <div>
                    <p className="text-lg text-gray-400">Chart</p>
                    <p className="text-3xl font-extrabold tracking-tight dark:text-gray-200 text-slate-900">Pie</p>
                </div>
                <p className="text-center dark:text-gray-200 text-xl mb-2 mt-3">Project Cost Breakdown</p>
            </div>
            <AccumulationChartComponent
                id="chart-pie"
                legendSettings={{ visible: true, background: 'white' }}
                height="400px"
                tooltip={{ enable: true }}
                background="#fff"
            >
                <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
                <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                        name="Sale"
                        dataSource={pieChartData}
                        xName="x"
                        yName="y"
                        innerRadius="40%"
                        startAngle={0}
                        endAngle={360}
                        radius="70%"
                        explode
                        explodeOffset="10%"
                        explodeIndex={2}
                        dataLabel={{
                            visible: true,
                            name: 'text',
                            position: 'Inside',
                            font: {
                                fontWeight: '600',
                                color: '#fff',
                            },
                        }}
                    />
                </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
        </div>
    );
};

export default PieChart;
