import React, { useState, useContext, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './blog.css';
import axios from "axios";
import ApiContext from '../api/ApiContext';
import { AnalyticsFilter } from "@aws-sdk/client-s3";

function CreatePost() {
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [files, setFiles] = useState([]);
    const [datePosted] = useState(new Date().toISOString().split('T')[0]); // Get current date
    const [accountId, setAccountId] = useState(); // Replace with logged-in user's account ID
    const [images, setImages] = useState([]); // List of images with names and Base64 data
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imagesName, setImagesName] = useState([]);
    const { accountData, khachhang } = useContext(ApiContext); // Use data from Context

    useEffect(() => {
        if (!khachhang && !accountData) return;
        else {
            setAccountId(accountData.ID)
        }
    }, [accountData, khachhang]);
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFiles(files);
        const promises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve({ name: file.name, base64: reader.result });
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises)
            .then((uploadedImages) => {
                setImages((prevImages) => [...prevImages, ...uploadedImages]);
                const uploadedNames = files.map((file) => file.name);
                setImagesName((prevNames) => [...prevNames, ...uploadedNames]);
            })
            .catch((error) => console.error('Error reading files:', error));
    };

    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setImagesName((prevNames) => prevNames.filter((_, i) => i !== index));
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!images || images.length === 0) {
            alert("Vui lòng chọn ảnh");
            return;
        }

        const formData = new FormData();
        files.forEach((image) => {
            console.log(image);  // In ra file đang được thêm vào FormData
            formData.append("images", image);  // Thêm từng file vào FormData
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST}/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            const uploadedUrls = response.data.fileUrls; // Lấy URL từ response
        } catch (error) {
            console.error("Lỗi khi tải ảnh:", error);
        }
    };

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const handlePostSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Replace base64 in content with actual URLs
        let updatedContent = content;
        images.forEach((image) => {
            const escapedBase64 = escapeRegExp(image.base64); // Escape Base64 string
            const regex = new RegExp(`<img src="${escapedBase64}"`, 'g');
            const imageUrl = `https://s3.tebi.io/image-web-travel/${image.name}`;
            updatedContent = updatedContent.replace(regex, `<img src="${imageUrl}"`); // Replace base64 with image URL
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/posts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title,
                    content: updatedContent,
                    datePosted,
                    accountId: accountId,
                    image_path: 'https://s3.tebi.io/image-web-travel/' + imagesName[0],
                }),
            });

            const data = await response.json();
            if (response.ok) {
                alert('Post saved successfully');
                setTitle('');
                setContent('');
                setImages([]);
            } else {
                alert('Failed to save post');
            }
        } catch (error) {
            console.error('Error saving post:', error);
            alert('Error saving post');
        } finally {
            setIsSubmitting(false);
        }
    };
    console.log(images);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Ngăn hành vi mặc định của form
        if (images.length > 0) {
            try {
                await handleUpload(e); // Chờ upload ảnh xong
                await handlePostSubmit(e); // Sau đó mới gửi post
                window.location.href = '/profile/account-blog'
            } catch (error) {
                console.error('Lỗi khi xử lý submit:', error);
            }
        } else alert('Không có ảnh')

    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['link'],
            [{ color: [] }],
            [{ background: [] }],
            ['clean'],
        ],
    };

    if (!accountData) return <div className=''>Loading...</div>;
    return (
        <div className="p-4 max-w-3xl mx-auto overflow-y-auto max-h-[700px]">
            <h1 className="text-2xl font-bold mb-4">Create Post</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Post Title"
                        required
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                    />
                </div>

                <div className="mb-4">
                    <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-100 file:text-blue-600 hover:file:bg-blue-200"
                    />
                    <ul className="mt-2 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                        {images.map((image, index) => (
                            <li key={index} className="flex flex-col items-center">
                                <img
                                    src={image.base64}
                                    alt={image.name}
                                    className="w-24 h-24 object-cover rounded-md shadow"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleRemoveImage(index)}
                                    className="mt-2 text-red-500 hover:text-red-700"
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="mb-4">
                    <label className="preview-content">Post Content:</label>
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                    />
                </div>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`px-6 py-2 text-white rounded ${isSubmitting ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'}`}
                >
                    {isSubmitting ? 'Saving...' : 'Save Post'}
                </button>
            </form>

            <div className="mt-8 p-4 border rounded bg-gray-100">
                <h2 className="text-lg font-bold mb-3">Post Preview:</h2>
                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="prose max-w-none preview-content"
                />
            </div>
        </div>
    );
}

export default CreatePost;
