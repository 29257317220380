import React from 'react';

const ErrorPage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-8">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-600 mb-6">404</h1>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Đã xảy ra lỗi</h2>
                <p className="text-lg text-gray-600 mb-8">
                    Xin lỗi, trang bạn yêu cầu không thể truy cập được.
                </p>
                <button
                    onClick={() => window.location.href = '/'}
                    className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
                >
                    Quay lại trang chủ
                </button>
            </div>
        </div>
    );
};

export default ErrorPage;
