import React, { createContext, useState, useEffect } from 'react';

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        // Load the translation file based on the selected language
        import(`/public/locales/${language}.json`).then((data) => {
            setTranslations(data);
        });
    }, [language]);

    const changeLanguage = (lang) => setLanguage(lang);

    return (
        <TranslationContext.Provider value={{ translations, changeLanguage }}>
            {children}
        </TranslationContext.Provider>
    );
};
