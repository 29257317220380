import React, { useState, useContext, useEffect } from "react";
import ApiContext from '../../api/ApiContext';
import axios from "axios";

function AccountGeneral() {
    const token = localStorage.getItem('token');
    const [khachhang, setKH] = useState([]);
    const [ProfilePicture, setProfilePicture] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // State để bật/tắt modal
    const [formData, setFormData] = useState({
        TenKH: '',
        Email: '',
        SDT: '',
        // NgaySinh: { ngay: '', thang: '', nam: '' },
        DiaChi: '',
        NoiDung: '',
        AccountID: '',
    });
    const { accountData } = useContext(ApiContext); // Use data from Context
    useEffect(() => {
        if (accountData) {
            setProfilePicture(accountData.ProfilePicture)
        }
    }, [accountData]);
    useEffect(() => {
        if (khachhang) {
            setFormData({
                TenKH: khachhang.TenKH || '',
                Email: khachhang.Email || '',
                SDT: khachhang.SDT || '',
                DiaChi: khachhang.DiaChi || '',
                NoiDung: khachhang.NoiDung || '',
                AccountID: khachhang.AccountID || '',
            });
        }
    }, [khachhang]);
    //Tự động tạo khách hàng và lấy dữ liệu
    useEffect(() => {

        const fetchCustomers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/api/khachhang/login`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (!response.ok) {
                    console.error("Lỗi HTTP:", response.status);
                    await createNewCustomer();
                    return;  // Ngừng thực thi nếu có lỗi HTTP
                }
                else {
                    setKH(data)
                }
            } catch (error) {
                console.error("Lỗi khi fetch dữ liệu khách hàng:", error);
            }
        };
        const createNewCustomer = async () => {
            if (!accountData) return;

            const newCustomerData = {
                TenKH: `No.${accountData.ID}` || '',
                Email: accountData.Mail || 'noemail@example.com',
                SDT: '0xxx',
                DiaChi: 'Not',
                NoiDung: 'Not',
                AccountID: accountData.ID,
            };

            try {
                const postResponse = await fetch(`${process.env.REACT_APP_HOST}/api/khachhang`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newCustomerData),
                });
                setKH(newCustomerData);
                const postResult = await postResponse.json();
            } catch (error) {
                console.error("Lỗi khi gọi API tạo khách hàng:", error);
            }
        };

        fetchCustomers();
    }, [accountData]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    //lấy hoặc lưu dữ liệu người dùng
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Kiểm tra các trường hợp thiếu thông tin
        if (!formData.TenKH || !formData.SDT || !formData.DiaChi) {
            alert("Vui lòng điền đầy đủ thông tin!");
            return;
        }

        // Kiểm tra định dạng số điện thoại (ví dụ)
        const phoneRegex = /^[0-9]{10}$/; // 10 chữ số
        if (!phoneRegex.test(formData.SDT)) {
            alert("Số điện thoại không hợp lệ!");
            return;
        }

        // Tiếp tục gửi dữ liệu
        try {
            const response = await fetch(
                khachhang ? `${process.env.REACT_APP_HOST}/api/khachhang/${khachhang.AccountID}` : `${process.env.REACT_APP_HOST}/api/khachhang`,
                {
                    method: khachhang ? 'PUT' : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                }
            );
            alert('Cập nhật thành công');

            const result = await response.json();
            // Giả sử API trả về một thuộc tính 'success' hoặc 'message'
            if (result) {
                alert('Cập nhật thành công');
            } else {
                alert(result.error || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.error('Lỗi khi gửi dữ liệu:', error);
        }
    };
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const MAX_SIZE = 100 * 1024; // 100KB

    const resizeImage = (file, maxSize, callback) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = function (e) {
            img.src = e.target.result;
        };

        reader.readAsDataURL(file);

        img.onload = function () {
            let width = img.width;
            let height = img.height;

            // Tính toán chiều rộng và chiều cao mới để giảm kích thước
            if (width > 800) {
                height = (height / width) * 800;
                width = 800;
            }

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob((blob) => {
                if (blob.size > maxSize) {
                    // Nếu kích thước vẫn lớn hơn MAX_SIZE, tiếp tục nén thêm
                    resizeImage(blob, maxSize, callback);
                } else {
                    callback(blob);
                }
            }, 'image/jpeg', 0.4);  // Chất lượng giảm xuống 70%
        };
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file) {
            alert("Vui lòng chọn ảnh");
            return;
        }

        // Kiểm tra kích thước file gốc
        if (file.size > MAX_SIZE) {
            resizeImage(file, MAX_SIZE, async (compressedFile) => {
                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = async () => {
                    const base64Image = reader.result;

                    // Gửi chuỗi Base64 đến server
                    try {
                        const token = localStorage.getItem("token"); // Lấy JWT token từ localStorage
                        const response = await axios.post(`${process.env.REACT_APP_HOST}/api/account/upload-profile-picture`,
                            { image: base64Image },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );

                        setMessage(response.data.message);
                        return window.location.href = '/profile/account-general';
                    } catch (error) {
                        console.error("Lỗi khi tải ảnh:", error);
                        setMessage("Đã xảy ra lỗi. Vui lòng thử lại.");
                        alert("Kích thước ảnh bạn quá lớn");
                    }
                };
            });
        } else {
            // Nếu ảnh đã dưới 100KB, upload luôn
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                const base64Image = reader.result;

                // Gửi chuỗi Base64 đến server
                try {
                    const token = localStorage.getItem("token"); // Lấy JWT token từ localStorage
                    const response = await axios.post(`${process.env.REACT_APP_HOST}/api/account/upload-profile-picture`,
                        { image: base64Image },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    return window.location.href = '/profile/account-general';
                    setMessage(response.data.message);
                } catch (error) {
                    console.error("Lỗi khi tải ảnh:", error);
                    setMessage("Đã xảy ra lỗi. Vui lòng thử lại.");
                }
            };
        }
    };


    if (!khachhang || !ProfilePicture) return <div className=''>Loading...</div>;
    return (
        <div className="p-4 bg-white rounded-lg shadow-lg">
            <div className=" flex flex-col lg:flex-row lg: justify-between items-center">
                <img
                    src={ProfilePicture}
                    alt=""
                    className="w-20 h-20 rounded-full object-cover"
                />
                <div className="w-full px-8">
                    <label className="text-sm font-medium text-gray-700">Đổi ảnh mới</label>
                    <form className="flex flex-col" onSubmit={handleUpload}>
                        <input type="file" onChange={handleFileChange} />
                        <button type="submit" className="lg:w-1/3 p-2 mt-2 text-sm border-2 font-bold rounded-xl hover:text-white hover:border-[#ff7e0e] hover:shadow-xl bg-orange-300">
                            Lưu ảnh
                        </button>
                    </form>
                    <div className="text-gray-500 text-xs mt-1">Allowed JPG, GIF or PNG. Max size of 100K</div>
                </div>
                <button
                    className="btn h-1/2 btn-primary py-2 px-4 bg-orange-500 text-white rounded-md hover:bg-orange-700"
                    onClick={() => setIsModalOpen(true)} // Mở modal
                >
                    Cập nhật thông tin
                </button>
            </div>

            <hr className="border-t border-gray-300 my-2" />
            <div className="">
                <form>
                    <h6 className="text-lg font-medium text-gray-900 mb-4">Thông tin</h6>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Họ và Tên</label>
                        <input
                            type="text"
                            name="TenKH"
                            placeholder="Chưa có thông tin"
                            className="w-full p-2 border border-gray-300 rounded-md mt-2 text-gray-700"
                            value={khachhang.TenKH}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <hr className="border-t border-gray-300 my-2" />
                    <h6 className="text-lg font-medium text-gray-900 mb-4">Liên hệ</h6>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Số điện thoại</label>
                        <input
                            type="text"
                            name="SDT"
                            placeholder="Chưa có thông tin"
                            className="w-full p-2 border border-gray-300 rounded-md mt-2 text-gray-700"
                            value={khachhang.SDT}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            className="w-full p-2 border border-gray-300 rounded-md mt-2 text-gray-700"
                            value={khachhang.Email}
                            name="Email"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                            readOnly
                        />
                        <div className="alert alert-warning text-yellow-600 text-xs mt-3 p-2 bg-yellow-100 rounded-md">
                            Email của bạn chưa được xác minh. Vui lòng kiểm tra hộp thư của bạn.<br />
                            <a href="" className="text-blue-500 hover:underline">Gửi lại thông báo</a>
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Địa chỉ</label>
                        <input
                            type="text"
                            name="DiaChi"
                            placeholder="Chưa có thông tin"
                            className="w-full p-2 border border-gray-300 rounded-md mt-2 text-gray-700"
                            value={khachhang.DiaChi}
                            onChange={handleChange}
                        />
                    </div>
                    <hr className="border-t border-gray-300 my-2" />
                    <h6 className="text-lg font-medium text-gray-900 mb-4">Tiểu sử</h6>
                    <div className="form-group mb-4">
                        <label className="block text-sm font-medium text-gray-700">Nội dung</label>
                        <textarea
                            name="NoiDung"
                            placeholder="Chưa có thông tin"
                            className="w-full p-2 border border-gray-300 rounded-md mt-2 text-gray-700"
                            value={khachhang.NoiDung}
                            onChange={handleChange}
                        />
                    </div>
                </form>
                {/* Modal */}
            </div>
            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-lg font-semibold mb-4">Cập nhật thông tin</h2>
                        {/* Đảm bảo form có onSubmit, bỏ onClick ở button */}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Họ và Tên
                                </label>
                                <input
                                    type="text"
                                    name="TenKH"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    value={formData.TenKH}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Số điện thoại
                                </label>
                                <input
                                    type="text"
                                    name="SDT"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    value={formData.SDT}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Địa chỉ
                                </label>
                                <input
                                    type="text"
                                    name="DiaChi"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    value={formData.DiaChi}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Nội dung
                                </label>
                                <textarea
                                    name="NoiDung"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    value={formData.NoiDung}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <a
                                    href="/profile/account-general"
                                    onClick={() => setIsModalOpen(false)}
                                    className="btn btn-primary py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700"
                                >
                                    Đóng
                                </a>
                                <button
                                    type="submit"
                                    className="btn btn-primary py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    Lưu
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>

    );

} export default AccountGeneral;
