import React, { useState, useEffect } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

function AccountChangepassword() {
    const [istoge, setToge] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [countdown, setCountdown] = useState(0); // Thời gian đếm ngược (giây)

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer); // Dọn dẹp bộ đếm
        }
    }, [countdown]);

    const handleRequestToken = async () => {
        if (countdown > 0) {
            alert(`Vui lòng đợi ${countdown} giây trước khi gửi lại mã xác nhận.`);
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_HOST}/api/account/request-change-password`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );

            const { message, token, userMail } = response.data;
            alert(message);
            // Gửi mã xác nhận qua EmailJS
            const emailParams = {
                to_email: userMail,
                message: `Your password reset code is: ${token}`,
            };

            emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE,
                process.env.REACT_APP_EMAILJS_TEMPLATE_SendAdmin,
                emailParams,
                process.env.REACT_APP_EMAILJS_kEY
            )
                .then(() => {
                    setToge(true);
                    setCountdown(60); // Bắt đầu đếm ngược 60 giây
                })
                .catch((error) => {
                    console.error('EmailJS error:', error);
                });
        } catch (error) {
            console.error('Error requesting token:', error);
            alert('Lỗi khi gửi yêu cầu mã xác nhận.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("Mật khẩu mới và xác nhận mật khẩu không khớp!");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST}/api/account/change-password`, {
                token,
                newPassword,
            }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

            alert(response.data.message);
            return window.location.href = '/profile/account-change-password';

        } catch (error) {
            alert('Thay đổi mật khẩu thất bại.');
        }
    };

    return (
        <div className="flex flex-col gap-2 h-full">
            <div className="p-4 w-full mx-auto bg-white rounded-lg shadow-lg">
                <button
                    onClick={handleRequestToken}
                    className={`mt-2 rounded-xl w-full font-semibold text-white py-2 ${countdown > 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                        }`}
                    disabled={countdown > 0}
                >
                    {countdown > 0 ? `Vui lòng đợi ${countdown} giây` : 'Gửi mã xác nhận đến email'}
                </button>
                {istoge ? <div className="mt-4">
                    <label className="text-sm font-medium text-red-500 mb-2">
                        Mã xác nhận đã gửi đến email của bạn
                    </label>
                </div> : ''}
            </div>
            {istoge ? <div className="p-4 w-full mx-auto bg-white rounded-lg shadow-lg">
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="mb-4 w-full">
                        <label className="text-sm font-medium text-gray-700 mb-2">
                            Nhập mã xác nhận đã gửi đến email
                        </label>
                        <input
                            type="text"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-4 w-full">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Nhập mật khẩu mới
                        </label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-4 w-full">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Nhập lại mật khẩu mới
                        </label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-orange-400 hover:bg-orange-500 rounded-xl w-full h-1/2 font-semibold text-white py-2"
                    >
                        Thay đổi mật khẩu
                    </button>
                </form>
            </div> : ''
            }

        </div>
    );
}

export default AccountChangepassword;
