import '../../css/edit.css'
import user from '../../img/hero/traveluser.png'
import { FaPlay } from "react-icons/fa";
function Home1() {
    return (
        <div className='lg:w-screen lg:h-screen bg-img'>
            <div className=' flex max-sm:px-8 max-sm:py-20 sm:px-10 sm:py-28 md:px-12 md:py-32 lg:px-16 lg:py-36'>
                <div className='lg:w-[40%]'>
                    <h1 className='text-orange-500 font-bold max-sm:text-sm sm:text-md lg:text-lg xl:text-2xl '>BEST DESTINATION AROUND THE WORLD</h1>
                    <div className='max-sm:text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-7xl pt-5 max-md:pt-2 font-medium tracking-tighter'>Travel, enjoy and live a new and full life</div>
                    <div className='pt-5 max-sm:text-md sm:text-md sm:pt-2 md:text-lg lg:text-xl xl:text-2xl'>Built Wicket longer admire do barton vanity itself do in it. Preferred to sportsmen it engrossed listening. Park gate sell they west hard for the.</div>
                    <div>
                        <div className='flex items-center justify-start mt-5'>
                            <button className='px-4 py-3 text-center rounded-lg text-white bg-[#F1A501] max-sm:text-[8px] sm:h-10 sm:text-[10px] sm:px-1 '>Find out more</button>
                            <div className='ml-5 scale-[0.8]'>
                                <button className='p-3 rounded-full bg-[#DF6951]'><FaPlay className='text-white' /></button>
                            </div>
                            <div className='pl-2 font-semibold text-gray-500 max-md:text-[10px]'>Play Demo</div>
                        </div>
                    </div>

                </div>
                <img src={user} className="w-[55%] hidden lg:flex lg:h-[550px] xl:h-[600px]" data-aos='slide-left' />
            </div>
        </div>
    );
}
export default Home1;
