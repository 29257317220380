import React from 'react';
import CheckoutForm from './CheckoutForm'; // Import CheckoutForm

const CartLike = () => {
    const products = JSON.parse(localStorage.getItem('products')) || []; // Chuyển chuỗi JSON thành mảng hoặc gán mảng trống

    if (products.length === 0) return <p className='p-4 lg:p-40'>Chưa có tour nào được chọn</p>;

    return (
        <div className="container mx-auto p-4 lg:py-40">
            <h2 className="text-2xl font-bold mb-4">Giỏ hàng yêu thích</h2>
            <table className="min-w-full table-auto border-collapse">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border-b">Hình ảnh</th>
                        <th className="px-4 py-2 border-b">Tên Tour</th>
                        <th className="px-4 py-2 border-b">Giá Vé</th>
                        <th className="px-4 py-2 border-b">Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item) => (
                        <tr key={item.ChiTietGioHangID}>
                            <td className="px-4 py-2 border-b">
                                <div className="w-20 h-20 mx-auto">
                                    <img
                                        className="w-full h-full object-cover rounded-md border"
                                        src={item.TourImage}
                                        alt={item.TourName}
                                    />
                                </div>
                            </td>
                            <td className="px-4 py-2 border-b text-center">{item.TourName}</td>
                            <td className="px-4 py-2 border-b text-center">${item.TourPrice}</td>
                            <td className="px-4 py-2 border-b text-center ">
                                <button
                                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 mr-2"
                                    onClick={() => handleRemove(item.ChiTietGioHangID)}
                                >
                                    Xóa
                                </button>
                                <button
                                    className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                >
                                    Mua
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    // Hàm xử lý xóa sản phẩm
    function handleRemove(id) {
        const updatedProducts = products.filter((item) => item.ChiTietGioHangID !== id);
        localStorage.setItem('products', JSON.stringify(updatedProducts));
        window.location.reload(); // Cập nhật lại giao diện
    }
};

export default CartLike;
