import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";

const CarBookingForm = () => {
    const [cars, setCars] = useState([]); // Lưu danh sách xe
    const [loading, setLoading] = useState(true); // Trạng thái đang tải
    const [error, setError] = useState(null); // Trạng thái lỗi
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        carId: '',
        rentalDate: '',
        returnDate: '',
        totalPrice: 0
    });
    const [selectedCar, setSelectedCar] = useState(null); // Thông tin xe đã chọn

    const [submittedData, setSubmittedData] = useState(null);

    // Xử lý khi người dùng nhập thông tin
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Xử lý khi người dùng chọn xe
    const handleSelectCar = (car) => {
        setSelectedCar(car); // Lưu thông tin xe đã chọn
        setFormData((prevFormData) => ({
            ...prevFormData,
            carId: car.CarId, // Cập nhật carId trong formData
            totalPrice: car.CarPrice, // Cập nhật giá thuê xe
        }));
    };


    // Gọi API để lấy danh sách xe
    useEffect(() => {
        const fetchCars = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/api/cars`); // Gọi API
                if (!response.ok) {
                    throw new Error("Lỗi khi gọi API");
                }
                const data = await response.json(); // Parse JSON
                setCars(data); // Cập nhật danh sách xe
            } catch (err) {
                setError(err.message); // Lưu lỗi nếu xảy ra
            } finally {
                setLoading(false); // Hoàn tất
            }
        };

        fetchCars();
    }, []);

    const handleSubmitCar = (e) => {
        e.preventDefault();

        // Kiểm tra thông tin khách hàng
        if (!formData.name || !formData.phone || !formData.email || !selectedCar) {
            alert("Vui lòng điền đầy đủ thông tin!");
            return;
        }

        const requestData = {
            ...formData,
            customerName: formData.name, // Đảm bảo khách hàng có tên
            customerPhone: formData.phone,
            customerEmail: formData.email
        };

        fetch(`${process.env.REACT_APP_HOST}/api/book-car`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                console.log(response); // Kiểm tra phản hồi của API
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    console.log(data.error); // Log lỗi nếu có
                } else {
                    setSubmittedData(formData);
                    handleSubmitMail(e);
                    alert("Đặt xe thành công!");
                    setFormData({ name: '', phone: '', email: '', carId: '', rentalDate: '', returnDate: '', totalPrice: 0 });
                }
            })
            .catch((err) => {
                alert("Lỗi khi đặt xe");
            });
    };

    // Xử lý khi form được submit
    const handleSubmitMail = (e) => {
        e.preventDefault();
        setError("");
        const templateParams = {
            to_email: formData.email,
            to_name: formData.name,
            // to_email: 'haiztvn@gmail.com',
            // to_name: 'Hải',
            message: "Chung tôi đã nhận được thông tin đặc xe của bạn. Trong vòng 24h chúng tôi sẽ liên hệ để xử lý!",
        };

        // Gửi email bằng EmailJS
        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE,
                process.env.REACT_APP_EMAILJS_TEMPLATE_SendAdmin,
                templateParams,
                process.env.REACT_APP_EMAILJS_kEY
            )
            .then(
                (response) => {
                    console.log("Thành công", response);
                    alert("Email đã được gửi!");
                },
                (error) => {
                    console.log("Lỗi", error);
                    setError("Có lỗi xảy ra khi gửi email.");
                }
            );
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        handleSubmitCar(e);
    };
    if (loading) return <p>Đang tải dữ liệu...</p>;
    if (error) return <p>Lỗi: {error}</p>;

    return (
        <div className="relative px-4 py-20 lg:py-32 lg:px-10 flex justify-center items-center ">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-6 sm:gap-y-6 max-sm:gap-y-6">
                {/* Form Đặt Xe */}
                <div className='flex justify-center '>
                    <div className="w-full lg:sticky top-24 z-10 transition-all duration-300 h-max col-span-1 bg-gray-50 rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-6">Đặt Xe</h2>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <input
                                type="text"
                                name="name"
                                placeholder="Họ và tên"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="p-3 border rounded-lg text-base"
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Số điện thoại"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                className="p-3 border rounded-lg text-base"
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="p-3 border rounded-lg text-base"
                            />

                            {selectedCar && (
                                <div>
                                    <p><strong>Loại xe:</strong> {selectedCar.CarName}</p>
                                    <p><strong>Giá thuê:</strong> {selectedCar.CarPrice} VND</p>
                                </div>
                            )}
                            <input
                                type="date"
                                name="rentalDate"
                                value={formData.rentalDate}
                                onChange={handleChange}
                                required
                                className="p-3 border rounded-lg text-base"
                            />
                            <input
                                type="date"
                                name="returnDate"
                                value={formData.returnDate}
                                onChange={handleChange}
                                required
                                className="p-3 border rounded-lg text-base"
                            />
                            <button
                                type="submit"
                                className="p-3 bg-green-500 text-white rounded-lg font-medium hover:bg-green-600 transition duration-300"
                            >
                                Đặt xe
                            </button>
                        </form>
                    </div>
                </div>

                {/* Danh sách xe */}
                <div className="col-span-2 ">
                    <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {cars.slice(0, 8).map((car) => (
                            <li
                                key={car.CarId}
                                className="group relative border p-4 rounded-lg shadow-lg bg-white cursor-pointer"
                                onClick={() => handleSelectCar(car)} // Khi bấm chọn xe
                            >
                                <h3 className="text-lg font-semibold">{car.CarName}</h3>
                                <p>Loại xe: {car.CarType}</p>
                                <p>Giá thuê: {car.CarPrice} VND</p>
                                <p>
                                    Trạng thái:{" "}
                                    <span
                                        className={`${car.CarStatus === "available"
                                            ? "text-green-500"
                                            : "text-red-500"
                                            }`}
                                    >
                                        {car.CarStatus === "available" ? "Còn xe" : "Đã thuê"}
                                    </span>
                                </p>

                                {/* Nội dung hiển thị khi hover */}
                                <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-85 opacity-0 group-hover:opacity-100 text-white p-4 rounded-lg flex flex-col justify-between">
                                    <div>
                                        <p><strong>Chi tiết xe:</strong></p>
                                        <p>{car.Description}</p>
                                    </div>
                                </div>

                                {car.CarImage && (
                                    <img
                                        src={car.CarImage}
                                        alt={car.CarName}
                                        className="w-full h-auto mt-3 rounded-lg"
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CarBookingForm;
