import book from '../../img/bookatrip/bookatrip.png'
import { FaCarSide } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

function Home4() {
    return (
        <div className="w-full h-auto pt-10 px-5 md:pt-28 md:px-20 flex flex-col md:flex-row ">
            {/* Left Section */}
            <div data-aos='slide-right' className="w-full md:w-1/2 mb-10 md:mb-0">
                <div className="text-gray-500 font-semibold text-lg">Easy and Fast</div>
                <div className="w-full md:w-96 font-extrabold text-2xl sm:text-3xl md:text-4xl text-left">
                    Book Your Next Trip In 3 Easy Steps
                </div>

                {/* Steps */}
                <div className="flex flex-col gap-5 mt-5">
                    {/* Step 1 */}
                    <div className="flex">
                        <div className="bg-[#F0BB1F] w-10 h-10 rounded-xl flex justify-center items-center">
                            <FaLocationDot className="text-2xl sm:text-3xl text-white" />
                        </div>
                        <div className="flex flex-col ml-5">
                            <div className="font-bold text-gray-500">Choose Destination</div>
                            <div className="text-gray-500 text-sm sm:text-base w-full md:w-80">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, tortor tempus.
                            </div>
                        </div>
                    </div>

                    {/* Step 2 */}
                    <div className="flex">
                        <div className="bg-[#F15A2B] w-10 h-10 rounded-xl flex justify-center items-center">
                            <MdPayment className="text-2xl sm:text-3xl text-white" />
                        </div>
                        <div className="flex flex-col ml-5">
                            <div className="font-bold text-gray-500">Make Payment</div>
                            <div className="text-gray-500 text-sm sm:text-base w-full md:w-80">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, tortor tempus.
                            </div>
                        </div>
                    </div>

                    {/* Step 3 */}
                    <div className="flex">
                        <div className="bg-[#006380] w-10 h-10 rounded-xl flex justify-center items-center">
                            <FaCarSide className="text-2xl sm:text-3xl text-white" />
                        </div>
                        <div className="flex flex-col ml-5">
                            <div className="font-bold text-gray-500">Reach Airport on Selected Date</div>
                            <div className="text-gray-500 text-sm sm:text-base w-full md:w-80">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, tortor tempus.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Section */}
            <div data-aos='slide-left' className="w-full md:w-1/2 flex justify-center md:justify-end">
                <img
                    className="scale-100 w-80 h-auto md:w-[485px] md:h-[550px] -mt-10 md:-mt-20 md:ml-16 hover:scale-[1.2]"
                    src={book}
                    alt="description"
                />
            </div>
        </div>

    );
}
export default Home4;
