import { FaPaperPlane } from "react-icons/fa";
import React, { useState, useContext } from 'react';
import StarRating from '../properties/StarRating';
import { TranslationContext } from '../locales/TranslationContext';
import ApiContext from '../../api/ApiContext';

function Home3() {
    var productRating = 3;
    const { products } = useContext(ApiContext);
    const { translations } = useContext(TranslationContext);
    const { theloaidl } = useContext(ApiContext);
    
    if (!products) return <div className=''>Loading...</div>;
    return (
        <div className=' px-16 flex flex-col overflow-hidden'>
            <div className='flex flex-col items-center justify-center'>
                <h2 className='font-semibold text-gray-500 text-lg'>Top Selliing</h2>
                <h1 className='text-4xl font-bold pt-3 max-sm:text-2xl sm:text-3xl'>Top Destination</h1>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-10 py-4'>
                {products.slice(0, 4).map((product) => (
                    <a key={product.TourId} href={`/propertiesdetail/${product.TourId}`}>
                        <div data-aos="zoom-in" className='h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group'>
                            <div className='h-full'>
                                <div className='rounded-t-xl overflow-hidden'>
                                    <img
                                        className='rounded-t-xl aspect-square object-cover transform transition-transform duration-500 group-hover:scale-110'
                                        src={product.TourImage}
                                        alt='Alaska, Westminster to Greenwich River Thames'
                                    />
                                </div>
                                <div className='p-4 flex flex-col justify-between'>
                                    <div className='font-bold text-base'>
                                        {product.TenQuocGia}, {product.TourName}
                                    </div>
                                    <div className='h-full'>
                                        <div className='text-sm font-semibold text-gray-500'>Long Time: {product.TourDay} Day</div>
                                        <div className='text-sm flex font-semibold text-gray-500'>
                                            End Date: <span className='px-1'>{product.TourTime ? product.TourTime : 'Unlimited'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='p-2 py-4'>
                                <div className='h-[1px] bg-gray-300 my-2'></div>
                                <div className='flex justify-between items-center max-sm:flex-col lg:flex-col xl:flex-row xl:justify-between'>
                                    <StarRating rating={productRating} />
                                    <div className='text-orange-400 font-bold max-sm:py-2 lg:py-2 xl:p-0'>${product.TourPrice}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                ))}

                {/* Additional travel items can be added here in similar format */}

            </div>
        </div>

    );
}
export default Home3;

