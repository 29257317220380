import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WeatherApp = () => {
    const [address, setAddress] = useState('');
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState('');
    const [timeOfDay, setTimeOfDay] = useState('');

    useEffect(() => {
        const fetchUserLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        setAddress(`${latitude},${longitude}`);
                        await fetchWeather(`${latitude},${longitude}`);
                    },
                    (err) => {
                        console.error(err.message);
                        setError('Unable to retrieve your location. Please enter an address.');
                    }
                );
            } else {
                setError('Geolocation is not supported by your browser.');
            }
        };

        fetchUserLocation();
    }, []);

    const fetchWeather = async (query) => {
        try {
            setError('');
            setWeather(null);

            const apiKey = 'cd024d6e7502486aba5155338241112';
            const url = `https://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${query}&days=7`;

            const response = await axios.get(url);
            const data = response.data;

            setWeather({
                current: {
                    temp_c: data.current.temp_c,
                    condition: data.current.condition,
                    wind_kph: data.current.wind_kph,
                    humidity: data.current.humidity,
                    cloud: data.current.cloud,
                },
                location: data.location.name,
                region: data.location.region,
                country: data.location.country,
                localTime: data.location.localtime,
                currentTemp: data.current.temp_c,
                currentCondition: data.current.condition.text,
                windSpeed: data.current.wind_kph,
                humidity: data.current.humidity,
                cloudCover: data.current.cloud,
                forecast: data.forecast.forecastday,
            });
            console.log(data);
            const timeOfDay = getTimeOfDay(data.location.localtime);
            setTimeOfDay(timeOfDay);
        } catch (err) {
            setError('Unable to fetch weather information. Please try again.');
        }
    };

    const formatLocalTime = (localTime) => {
        const date = new Date(localTime);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
        return date.toLocaleTimeString([], options);
    };

    const isWeatherGoodForOutdoor = (current) => {
        const { temp_c, wind_kph, humidity, cloud, condition } = current;

        // Phân tích nhiệt độ
        let tempMessage = '';
        if (temp_c < 15) {
            tempMessage = `${temp_c}°C quá lạnh, không thích hợp cho các hoạt động ngoài trời.`;
        } else if (temp_c >= 15 && temp_c < 20) {
            tempMessage = `${temp_c}°C lạnh, có thể không thoải mái nếu hoạt động ngoài trời lâu.`;
        } else if (temp_c >= 20 && temp_c < 26) {
            tempMessage = `${temp_c}°C lý tưởng cho các hoạt động ngoài trời, cảm giác dễ chịu.`;
        } else if (temp_c >= 26 && temp_c < 28) {
            tempMessage = `${temp_c}°C có thể hơi nóng, cần lưu ý nghỉ ngơi và uống nước.`;
        } else if (temp_c >= 28 && temp_c < 30) {
            tempMessage = `${temp_c}°C nóng và không thoải mái, tránh hoạt động ngoài trời lâu.`;
        } else {
            tempMessage = `${temp_c}°C quá nóng, có thể gây mệt mỏi và khó chịu nếu tham gia hoạt động ngoài trời.`;
        }

        // Phân tích độ ẩm
        let humidityMessage = '';
        if (humidity < 50) {
            humidityMessage = `Độ ẩm ${humidity}% lý tưởng, không gây cảm giác bí bách.`;
        } else if (humidity >= 50 && humidity < 60) {
            humidityMessage = `Độ ẩm ${humidity}% thoải mái, có thể cảm thấy hơi nóng nếu hoạt động lâu.`;
        } else if (humidity >= 60 && humidity < 80) {
            humidityMessage = `Độ ẩm ${humidity}% có thể hơi bí bách, cần nghỉ ngơi thường xuyên.`;
        } else {
            humidityMessage = `Độ ẩm ${humidity}% quá cao, sẽ gây khó chịu và mệt mỏi khi tham gia hoạt động ngoài trời.`;
        }

        // Phân tích gió
        let windMessage = '';
        if (wind_kph < 10) {
            windMessage = `Gió ${wind_kph} km/h nhẹ, lý tưởng cho các hoạt động ngoài trời.`;
        } else if (wind_kph >= 10 && wind_kph < 20) {
            windMessage = `Gió ${wind_kph} km/h nhẹ đến vừa phải, dễ chịu cho các hoạt động ngoài trời.`;
        } else if (wind_kph >= 20 && wind_kph < 30) {
            windMessage = `Gió ${wind_kph} km/h vừa phải, có thể làm chậm các hoạt động ngoài trời.`;
        } else {
            windMessage = `Gió ${wind_kph} km/h mạnh, có thể gây khó khăn cho các hoạt động ngoài trời.`;
        }

        // Phân tích mây
        let cloudMessage = '';
        if (cloud < 20) {
            cloudMessage = `Trời quang, ánh sáng ngoài trời lý tưởng.`;
        } else if (cloud >= 20 && cloud < 50) {
            cloudMessage = `Trời ít mây, ánh sáng vẫn dễ chịu cho các hoạt động ngoài trời.`;
        } else {
            cloudMessage = `Trời nhiều mây, ánh sáng giảm bớt, có thể gây khó khăn cho một số hoạt động ngoài trời.`;
        }

        // Kiểm tra điều kiện mưa
        const rainMessage = condition.text.toLowerCase().includes('rain') ? 'Mưa, không phù hợp cho các hoạt động ngoài trời.' : 'Không có mưa, điều kiện lý tưởng cho các hoạt động ngoài trời.';
        // Tính toán tóm tắt thông điệp
        // Tính toán tóm tắt thông điệp
        let summaryMessage = '';
        let adviceMessage = '';

        if (temp_c < 5) {
            summaryMessage = 'Nhiệt độ quá lạnh, không thích hợp cho các hoạt động ngoài trời.';
            adviceMessage = 'Tránh ra ngoài hoặc nếu cần thiết, mặc quần áo ấm, chuẩn bị các thiết bị bảo vệ như găng tay, mũ, giày ấm và tránh hoạt động quá lâu ngoài trời.';
        } else if (temp_c < 15 || wind_kph > 20) {
            summaryMessage = 'Cân nhắc trước khi ra ngoài.';
            adviceMessage = 'Cân nhắc về việc ra ngoài tùy vào hoạt động cụ thể. Nếu là các hoạt động nhẹ nhàng, hãy mặc quần áo giữ ấm và bảo vệ cơ thể khỏi gió mạnh. Nếu gió quá mạnh, nên tránh các hoạt động ngoài trời có thể gây mất sức.';
        } else {
            summaryMessage = 'Điều kiện thời tiết khá tốt cho các hoạt động ngoài trời, nhưng cần chú ý đến độ ẩm hoặc gió.';
            adviceMessage = 'Các hoạt động ngoài trời khá lý tưởng. Tuy nhiên, nếu độ ẩm cao, cần lựa chọn các hoạt động nhẹ nhàng và uống đủ nước để tránh mệt mỏi. Nếu gió mạnh, hãy chú ý để không bị ảnh hưởng tới sức khỏe.';
        }
        // Trả về phân tích thời tiết
        return {
            status: (temp_c >= 20 && temp_c <= 25 && wind_kph <= 30 && humidity < 60 && cloud < 50 && !condition.text.toLowerCase().includes('rain')) ? 'good' :
                (temp_c < 5 || wind_kph > 20 || humidity > 80 || cloud > 50) ? 'bad' : 'consider',
            message: `${tempMessage} ${humidityMessage} ${windMessage} ${cloudMessage} ${rainMessage}`,
            messagesummary: `${summaryMessage}`,
            messageadvice: `${adviceMessage}`
        };
    };


    const InfoCard = ({ title, value }) => (
        <div className="flex flex-col items-center justify-center bg-blue-100 p-2 rounded-md shadow">
            <h3 className=" text-[10px] lg:text-sm font-semibold">{title}</h3>
            <p className="text-[10px] lg:text-lg font-bold">{value}</p>
        </div>
    );

    const getTimeOfDay = (localTime) => {
        const date = new Date(localTime);
        const hours = date.getHours();
        if (hours >= 6 && hours < 12) return 'morning';
        if (hours >= 12 && hours < 18) return 'afternoon';
        return 'night';
    };

    return (
        <div
            className={`w-screen min-h-screen flex flex-col justify-center py-24 px-4 md:px-10 gap-2 ${timeOfDay === 'morning' ? 'bg-gradient-to-b from-yellow-300 via-orange-200 to-yellow-100' :
                timeOfDay === 'afternoon' ? 'bg-gradient-to-b from-blue-500 via-blue-400 to-blue-300' :
                    'bg-gradient-to-b from-gray-800 via-gray-900 to-black'
                }`}
        >
            <div className="flex flex-col">
                <div className="flex items-center justify-center gap-4 overflow-hidden">
                    <div className="w-full p-4 bg-white rounded-lg shadow-md">
                        <div className="flex items-center gap-2">
                            <input
                                type="text"
                                placeholder="Enter an address (e.g., Hanoi)..."
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                onClick={() => fetchWeather(address)}
                                className="px-6 py-2 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition duration-300"
                            >
                                Check
                            </button>
                        </div>
                        {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 w-full'>
                <div className='flex flex-col gap-y-2'>
                    {weather && (
                        <div className=" bg-white rounded-lg shadow-md">
                            <h2 className="text-lg font-semibold text-gray-700 text-center">
                                {weather.location}, {weather.region}, {weather.country}
                            </h2>
                            <div className="grid grid-cols-5 gap-2 p-2">
                                <InfoCard title="Temperature" value={`${weather.currentTemp}°C`} />
                                <InfoCard title="Condition" value={weather.currentCondition} />
                                <InfoCard title="Wind" value={`${weather.windSpeed} km/h`} />
                                <InfoCard title="Humidity" value={`${weather.humidity}%`} />
                                <InfoCard title="Clouds" value={`${weather.cloudCover}%`} />
                            </div>
                            <p className="text-sm text-gray-500 mt-2 text-center">
                                Local Time: {formatLocalTime(weather.localTime)}
                            </p>
                        </div>
                    )}

                    {weather && (
                        <div className="w-full h-full text-left bg-white px-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold text-gray-700 mt-6">Daily Forecast:</h3>
                            <WeatherChart forecast={weather.forecast} />
                        </div>
                    )}
                </div>

                {weather && (
                    <div className=" text-left bg-white p-6 rounded-lg shadow-lg">
                        <p className={`font-bold pb-4 ${isWeatherGoodForOutdoor(weather.current).status === 'good' ? 'text-green-600' : isWeatherGoodForOutdoor(weather.current).status === 'consider' ? 'text-yellow-600' : 'text-red-600'}`}>
                            {isWeatherGoodForOutdoor(weather.current).status === 'good' ? 'Thời tiết lý tưởng để đi chơi!' :
                                isWeatherGoodForOutdoor(weather.current).status === 'consider' ? 'Cân nhắc trước khi ra ngoài.' :
                                    'Thời tiết không quá phù hợp để đi chơi.'}
                        </p>
                        <p>{isWeatherGoodForOutdoor(weather.current).message}</p>
                        <p className='font-bold'>{isWeatherGoodForOutdoor(weather.current).messagesummary}</p>
                        <div className='h-[2px] bg-slate-600 my-2'></div>
                        <p className='font-bold text-green-400'>{isWeatherGoodForOutdoor(weather.current).messageadvice}</p>
                    </div>
                )}
            </div>

        </div>
    );
};

const WeatherChart = ({ forecast }) => {
    const chartData = {
        labels: forecast.map(day => day.date),
        datasets: [
            {
                label: 'Max Temperature (°C)',
                data: forecast.map(day => day.day.maxtemp_c),
                fill: false,
                borderColor: 'rgba(255, 99, 132, 1)',
                tension: 0.1
            },
            {
                label: 'Min Temperature (°C)',
                data: forecast.map(day => day.day.mintemp_c),
                fill: false,
                borderColor: 'rgba(54, 162, 235, 1)',
                tension: 0.1
            },
            {
                label: 'Wind (km/h)',
                data: forecast.map(day => day.day.maxwind_kph),
                fill: false,
                borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.1
            },
            {
                label: 'Humidity (%)',
                data: forecast.map(day => day.day.avghumidity),
                fill: false,
                borderColor: 'rgba(153, 102, 255, 1)',
                tension: 0.1
            },
            {
                label: 'UV (uv)',
                data: forecast.map(day => day.day.uv),
                fill: false,
                borderColor: 'rgba(255, 159, 64, 1)',
                tension: 0.1
            }
        ]
    };

    return (
        <div className="w-full md:w-11/12 lg:w-full max-w-4xl mx-auto">
            <Line data={chartData} />
        </div>
    );
};


export default WeatherApp;
