function Footer() {
    return (
        <footer className="bg-gray-800 text-white py-12">
            <div className="container mx-auto px-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                {/* Logo and Description */}
                <div className="space-y-4">
                    <h1 className="text-3xl font-bold text-green-500">Car Rental</h1>
                    <p>
                        Chúng tôi cung cấp dịch vụ cho thuê xe với chất lượng cao và giá cả hợp lý. Hãy liên hệ với chúng tôi để trải nghiệm dịch vụ tuyệt vời.
                    </p>
                    <div className="flex space-x-4">
                        <a href="#" className="text-gray-400 hover:text-white">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" className="text-gray-400 hover:text-white">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="#" className="text-gray-400 hover:text-white">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                {/* Useful Links */}
                <div className="space-y-4">
                    <h3 className="font-semibold text-lg">Liên kết hữu ích</h3>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:text-green-500">Trang chủ</a></li>
                        <li><a href="#" className="hover:text-green-500">Dịch vụ</a></li>
                        <li><a href="#" className="hover:text-green-500">Chính sách bảo mật</a></li>
                        <li><a href="#" className="hover:text-green-500">Điều khoản sử dụng</a></li>
                    </ul>
                </div>

                {/* Contact Information */}
                <div className="space-y-4">
                    <h3 className="font-semibold text-lg">Liên hệ</h3>
                    <ul className="space-y-2">
                        <li><span className="font-bold">Địa chỉ:</span> 123 Đường ABC, Thành phố XYZ</li>
                        <li><span className="font-bold">Điện thoại:</span> 0123456789</li>
                        <li><span className="font-bold">Email:</span> info@carrental.com</li>
                    </ul>
                </div>

                {/* Newsletter Signup */}
                <div className="space-y-4">
                    <h3 className="font-semibold text-lg">Đăng ký nhận bản tin</h3>
                    <p>Nhận thông tin mới nhất về dịch vụ và khuyến mãi của chúng tôi.</p>
                    <form className="flex">
                        <input
                            type="email"
                            placeholder="Nhập email của bạn"
                            className="px-4 py-2 rounded-l-lg border border-gray-300 w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                        <button
                            type="submit"
                            className="bg-green-500 text-white px-6 py-2 rounded-r-lg hover:bg-green-600 transition duration-300"
                        >
                            Đăng ký
                        </button>
                    </form>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="bg-gray-900 text-gray-400 py-4 mt-8">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 Car Rental. Tất cả quyền được bảo lưu.</p>
                </div>
            </div>
        </footer>

    );
}
export default Footer;
