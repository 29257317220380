import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import ApiContext from "../../api/ApiContext";

const Orders = () => {
    const token = localStorage.getItem('token');
    const [orders, setOrders] = useState([]);
    const [message, setMessage] = useState('');
    const [paymentUrl, setPaymentUrl] = useState('');
    const [idKH, setKH] = useState([]);
    const [name, setNameKH] = useState([]);
    const { khachhang } = useContext(ApiContext);
    const [role, setRole] = useState("");
    const { accountData } = useContext(ApiContext);
    useEffect(() => {
        if (accountData) {
            setRole(accountData.Role);
        }
    }, [accountData]);
    useEffect(() => {
        if (khachhang) {
            setKH(khachhang.IdKH);
            setNameKH(khachhang.TenKH);
        }
    }, [khachhang]);
    useEffect(() => {
        // Lấy dữ liệu đơn hàng từ API
        axios.get(`${process.env.REACT_APP_HOST}/api/orders`)
            .then((response) => {
                setOrders(response.data);
            })
            .catch((error) => {
                console.error('Đã xảy ra lỗi khi lấy đơn hàng:', error);
            });
    }, []);
    const order = orders.filter((or) => or.KhachHangID === idKH)

    const handleCancelOrder = (orderId, userRole = role) => {  // Cung cấp giá trị mặc định cho userRole
        const orderToCancel = orders.find((order) => order.DonHangID === orderId);
        console.log('orderToCancel:', orderToCancel);  // Kiểm tra đối tượng đơn hàng
        console.log('userRole:', userRole);  // Kiểm tra vai trò người dùng

        if (orderToCancel) {
            console.log('order status:', orderToCancel.DonHangTrangThai);  // Kiểm tra trạng thái đơn hàng
        }

        // Kiểm tra điều kiện
        if ((userRole === 'user' && orderToCancel &&
            (orderToCancel.DonHangTrangThai === 'pending' || orderToCancel.DonHangTrangThai === 'processing')) ||
            userRole === 'admin') {

            fetch(`${process.env.REACT_APP_HOST}/api/orders/cancel/${orderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Gửi token vào header
                },
            })
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    setOrders((prevOrders) =>
                        prevOrders.map(order =>
                            order.DonHangID === orderId
                                ? { ...order, DonHangTrangThai: 'cancelled' }
                                : order
                        )
                    );
                })
                .catch((error) => {
                    console.error('Đã xảy ra lỗi khi hủy đơn hàng:', error);
                });
        } else {
            alert('Không thể hủy đơn hàng trong trạng thái này hoặc bạn không có quyền hủy');
        }
    };

    const handlePayment = async (orderId) => {
        if (!orderId && !name) return;
        console.log(orderId);
        try {
            const response = await fetch('http://localhost:8888/order/create_payment_url', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ DonHangID: orderId, TenKH: name })
            });

            const data = await response.json();
            if (response.ok) {
                setPaymentUrl(data.paymentUrl);
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            console.error('Error during payment:', error);
            setMessage('Lỗi hệ thống, vui lòng thử lại sau.');
        }
    };

    const handleDeleteOrder = (orderId) => {
        // Tìm trạng thái đơn hàng
        const orderToDelete = orders.find((order) => order.DonHangID === orderId);

        if (orderToDelete && (orderToDelete.DonHangTrangThai === 'completed' || orderToDelete.DonHangTrangThai === 'cancelled')) {
            axios.delete(`${process.env.REACT_APP_HOST}/api/orders/delete/${orderId}`)
                .then((response) => {
                    alert(response.data.message);
                    setOrders((prevOrders) =>
                        prevOrders.filter(order => order.DonHangID !== orderId)
                    );
                })
                .catch((error) => {
                    console.error('Đã xảy ra lỗi khi xóa đơn hàng:', error);
                });
        } else {
            alert('Không thể xóa đơn hàng trong trạng thái này');
        }
    };

    return (
        <div className="container relative mx-auto p-4">
            <h2 className="text-2xl font-semibold mb-4">Danh sách đơn hàng</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg overflow-hidden">
                    <thead>
                        <tr className="bg-gray-100 text-left">
                            <th className="py-2 px-4 border-b">Ngày Đặt</th>
                            <th className="py-2 px-4 border-b">Email</th>
                            <th className="py-2 px-4 border-b">Tên Tour</th>
                            <th className="py-2 px-4 border-b">Số Lượng</th>
                            <th className="py-2 px-4 border-b">Giá Vé</th>
                            <th className="py-2 px-4 border-b">Trạng Thái</th>
                            <th className="py-2 px-4 border-b">Hành Động</th>
                            <th className="py-2 px-4 border-b">Thanh Toán</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.map((order) => {
                            let statusClass = "";
                            switch (order.DonHangTrangThai) {
                                case "pending":
                                    statusClass = "bg-yellow-300 text-yellow-800"; // Màu vàng cho trạng thái "Đã đặt hàng"
                                    break;
                                case "processing":
                                    statusClass = "bg-blue-300 text-blue-800"; // Màu xanh dương cho trạng thái "Đang xử lý"
                                    break;
                                case "paid":
                                    statusClass = "bg-green-300 text-green-800"; // Màu xanh lá cho trạng thái "Đã thanh toán"
                                    break;
                                case "completed":
                                    statusClass = "bg-teal-300 text-teal-800"; // Màu xanh ngọc cho trạng thái "Hoàn tất"
                                    break;
                                case "cancelled":
                                    statusClass = "bg-red-300 text-red-800"; // Màu đỏ cho trạng thái "Hủy bỏ"
                                    break;
                                default:
                                    statusClass = "bg-gray-300 text-gray-800"; // Màu xám cho trạng thái mặc định
                            }

                            return (
                                <tr key={order.DonHangID} className="hover:bg-gray-50">
                                    <td className="py-2 px-4 border-b">{new Date(order.NgayDatHang).toLocaleDateString('vi-VN')}</td>
                                    <td className="py-2 px-4 border-b">{order.Email}</td>
                                    <td className="py-2 px-4 border-b text-xs">{order.TourName}</td>
                                    <td className="py-2 px-4 border-b">{order.SoLuong}</td>
                                    <td className="py-2 px-4 border-b">{order.GiaVe}</td>
                                    <td className="py-2 px-4 border-b">
                                        <button className={`px-2 rounded-xl ${statusClass}`}>{order.DonHangTrangThai}</button>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        {order.DonHangTrangThai !== 'cancelled' && (
                                            <button
                                                onClick={() => handleCancelOrder(order.DonHangID)}
                                                className="bg-red-500 text-white px-4 py-2 rounded-md">
                                                Hủy Đơn
                                            </button>
                                        )}
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        {order.DonHangTrangThai !== 'cancelled' && (
                                            <button
                                                onClick={() => handlePayment(order.DonHangID)}
                                                className="bg-green-500 text-white px-4 py-2 rounded-md">
                                                Thanh Toán
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {paymentUrl && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='bg-white p-4 rounded-lg'>
                        <div className='flex items-end justify-end'>
                            <button onClick={(e) => setPaymentUrl('')} className=' bg-red-500 px-2 text-white rounded-md'>x</button>
                        </div>
                        <a
                            href={paymentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline mt-4"
                        >
                            Click vào đây để thanh toán
                        </a>
                    </div>

                </div>
            )}
        </div>
    );
};

export default Orders;
