import '../css/edit.css'
import React, { useContext, useState, useEffect } from 'react';
import CreatePost from './addBlog';
import ApiContext from '../api/ApiContext';

function EditBlog() {
    const [isOpen, setIsOpen] = useState(false);
    const [post, setPost] = useState('');
    const { posts, accountData } = useContext(ApiContext); // Use data from Context

    useEffect(() => {
        if (!posts || !accountData) return; // Chờ dữ liệu sẵn sàng
        const post = posts.filter((blog) => blog.AccountId === accountData.ID)
        setPost(post);
    }, [posts, accountData]);
    // Hiển thị loading khi đang tải dữ liệu
    if (!post) {
        return <div>Đang tải...</div>;
    }

    return (
        <div className='w-full'>
            <div className='flex flex-col justify-center items-center'>
                <button onClick={() => setIsOpen(true)} className="flex items-center m-4 justify-center bg-white w-full py-3 rounded-sm font-semibold text-gray-500 border-2 hover:bg-orange-400 hover:text-white">
                    + Post Content
                </button>
                {isOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                        <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-4xl p-4 relative">
                            {/* Nút đóng */}
                            <button
                                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                                onClick={() => setIsOpen(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <CreatePost />
                        </div>
                    </div>

                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {post.map(post => (
                        <a
                            href={`/detailblog/${post.Id}`}
                            className="h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group"
                        >
                            <div className="h-full">
                                <div className="rounded-t-xl overflow-hidden">
                                    <img
                                        className="rounded-t-xl aspect-video object-cover transform transition-transform duration-500 group-hover:scale-110"
                                        src={post.image_path}
                                        alt="Alaska, Westminster to Greenwich River Thames"
                                    />
                                </div>
                                <div className="p-4 flex flex-col justify-between">
                                    <div className="">Date: {new Date(post.ngaydang).toLocaleDateString('vi-VN')}</div>
                                    <div className="font-bold text-base">
                                        {post.tieude}
                                    </div>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="h-[1px] bg-gray-300 my-2"></div>
                                <div className="relative w-auto overflow-hidden">
                                    <div

                                        className="text-sm group-hover:underline transition-all"
                                    >
                                        Read More
                                    </div>
                                    <div className="inset-y-0 left-0 bg-orange-500 w-0 h-[3px] group-hover:w-full transition-all duration-500 ease-in-out"></div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                <button className="w-full bg-white rounded-2xl my-6 border border-orange-400 text-center text-orange-400 py-2 hover:bg-orange-400 hover:text-white">
                    Load More
                </button>
            </div>
        </div>
    );
}
export default EditBlog;
