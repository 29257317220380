import travel from '../../img/services/travel.gif'
import car from '../../img/services/car.gif'
import telephone from '../../img/services/telephone.gif'
import cloudy from '../../img/services/cloudy.gif'
function Home2() {
    return (
        <div className='w-full flex flex-col sm:p-6 md:p-8 lg:p-20'>
            <div className='flex flex-col items-center justify-center'>
                <h2 className='font-semibold text-gray-500 text-lg'>CATEGORY</h2>
                <h1 className='text-4xl font-bold pt-3 max-sm:text-2xl sm:text-3xl'>We Offer Best Services</h1>
            </div>
            <div className='w-full mt-10 py-8 grid grid-cols-4 gap-4 max-sm:scale-[0.8] max-sm:mt-0 max-md:grid-cols-2 max-md:grid-rows-2 overflow-hidden'>
                <a href='/weather' className='w-full h-[250px] px-2 flex flex-col items-center justify-center rounded-2xl hover:shadow-2xl hover:bg-yellow-50 ' data-aos='fade-up' data-aos-delay="100" >
                    <img className='w-20 h-20 rounded-lg bg-[#feebcc] flex flex-row items-center justify-center shadow-2xl' src={cloudy} alt='description' />
                    <h1 className='pt-8 text-lg text-center font-semibold'>Calculated Weather </h1>
                    <h1 className='pt-4 text-md text-center text-gray-500'>Built Wicket longer admire do barton vanity itself do in it.</h1>
                </a>
                <a href='/carcall' className='w-full h-[250px] px-2 flex flex-col items-center justify-center rounded-2xl hover:shadow-2xl hover:bg-yellow-50 ' data-aos='fade-up' data-aos-delay="200">
                    <img src={car} className='w-20 h-20 rounded-lg bg-[#feebcc] flex flex-row items-center justify-center shadow-2xl' alt='description' />
                    <h1 className='pt-8 text-lg text-center font-semibold'>Best Flights</h1>
                    <h1 className='pt-4 text-md text-center text-gray-500'>Engrossed listening. Park gate sell they west hard for the.</h1>
                </a>
                <a href='/properties' className='w-full h-[250px] px-2 flex flex-col items-center justify-center rounded-2xl hover:shadow-2xl hover:bg-yellow-50 ' data-aos='fade-up' data-aos-delay="300">
                    <img className='w-20 h-20 rounded-lg bg-[#feebcc] flex flex-row items-center justify-center shadow-2xl' src={travel} alt='description' />
                    <h1 className='pt-8 text-lg text-center font-semibold'>Local Events</h1>
                    <h1 className='pt-4 text-md text-center text-gray-500'>Barton vanity itself do in it. Preferd to men it engrossed listening. </h1>
                </a>
                <div onClick={() => window.location.href = 'tel:+84377517769'} className='w-full h-[250px] px-2 flex flex-col items-center justify-center rounded-2xl hover:shadow-2xl hover:bg-yellow-50 ' data-aos='fade-up' data-aos-delay="400">
                    <img src={telephone} className='w-20 h-20 rounded-lg bg-[#feebcc] flex flex-row items-center justify-center shadow-2xl' />
                    <h1 className='pt-8 text-lg text-center font-semibold'>Call</h1>
                    <h1 className='pt-4 text-md text-center text-gray-500'>We deliver outsourced
                        aviation services for
                        military customers</h1>
                </div>
            </div>

        </div>

    );
}
export default Home2;
