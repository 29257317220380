import React, { useState, useEffect } from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts';

const TicketInfo = () => {
    const [ticketData, setTicketData] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        // Gọi API để lấy dữ liệu vé
        fetch(`${process.env.REACT_APP_HOST}/api/tours/ticket-info`)
            .then((response) => response.json())
            .then((data) => {
                setTicketData(data);

                // Xử lý dữ liệu để phù hợp với stacked chart
                const formattedData = data.map((tour) => ({
                    TourName: tour.TourName,
                    SoldTickets: tour.SoLuongVeDaBan,
                    RemainingTickets: tour.SoLuongVeConLai,
                }));

                setChartData(formattedData);
            })
            .catch((error) => console.error('Error fetching ticket data:', error));
    }, []);

    const stackedCustomSeries = [
        {
            dataSource: chartData,
            xName: 'TourName',
            yName: 'SoldTickets',
            name: 'Vé Đã Bán',
            type: 'StackingColumn',
        },
        {
            dataSource: chartData,
            xName: 'TourName',
            yName: 'RemainingTickets',
            name: 'Vé Còn Lại',
            type: 'StackingColumn',
        },
    ];

    const primaryXAxis = {
        valueType: 'Category',
        title: 'Tên Tour',
    };

    const primaryYAxis = {
        labelFormat: '{value}',
        title: 'Số Lượng Vé',
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Thông tin vé du lịch</h2>

            {/* Bảng dữ liệu vé */}
            <table border="1" style={{ width: '100%', marginBottom: '20px' }}>
                <thead>
                    <tr>
                        <th>Tên Tour</th>
                        <th>Số Lượng Vé Còn Lại</th>
                        <th>Số Lượng Vé Đã Bán</th>
                    </tr>
                </thead>
                <tbody>
                    {ticketData.map((tour) => (
                        <tr key={tour.TourId}>
                            <td>{tour.TourName}</td>
                            <td>{tour.SoLuongVeConLai}</td>
                            <td>{tour.SoLuongVeDaBan}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Biểu đồ stacked */}
            <ChartComponent
                id="stacked-chart"
                primaryXAxis={primaryXAxis}
                primaryYAxis={primaryYAxis}
                tooltip={{ enable: true }}
                chartArea={{ border: { width: 0 } }}
                legendSettings={{ background: 'white' }}
                height="400px"
            >
                <Inject services={[StackingColumnSeries, Category, Legend, Tooltip]} />
                <SeriesCollectionDirective>
                    {stackedCustomSeries.map((item, index) => (
                        <SeriesDirective key={index} {...item} />
                    ))}
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    );
};

export default TicketInfo;
