import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RevenueChartYear = () => {
    const [yearlyRevenueDetails, setYearlyRevenueDetails] = useState([]); // Doanh thu theo từng tháng trong năm

    useEffect(() => {
        // Fetch Yearly Data
        fetch(`${process.env.REACT_APP_HOST}/revenue/yearly`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Kiểm tra dữ liệu trả về
                if (Array.isArray(data.revenue)) {
                    // Khởi tạo dữ liệu đầy đủ cho các tháng trong năm (1-12)
                    const rearrangedData = new Array(12).fill({ monthly_revenue: 0 });

                    // Sắp xếp và sắp lại dữ liệu theo thứ tự tháng
                    data.revenue.forEach(item => {
                        rearrangedData[item.month_of_year - 1] = item;
                    });
                    setYearlyRevenueDetails(rearrangedData);
                } else {
                    setYearlyRevenueDetails([]);
                }
            });
    }, []);

    // Yearly Revenue Chart Data
    const yearlyData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Yearly Revenue',
                data: yearlyRevenueDetails.map(item => item.monthly_revenue || 0),
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
            },
        ],
    };
    return (
        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
            {/* Yearly Revenue Section */}
            <div className="mb-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Yearly Revenue</h2>
                <div className="bg-white p-4 rounded-lg shadow">
                    <Bar data={yearlyData} />
                </div>
            </div>
        </div>
    );
};

export default RevenueChartYear;
