import React, { createContext, useState, useEffect } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const token = localStorage.getItem('token');
    const [products, setTours] = useState([]);
    const [quocgia, setQG] = useState([]);
    const [theloaidl, setTL] = useState([]);
    const [khachhang, setKH] = useState([]);
    const [khachhangs, setKHS] = useState([]);
    const [accounts, setAcc] = useState([]);
    const [posts, setPosts] = useState([]);
    const [accountData, setAccountData] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST}/api/tourdl`)
            .then((response) => response.json())
            .then((data) => {
                setTours(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
        ////
        fetch(`${process.env.REACT_APP_HOST}/api/quocgia`)
            .then((response) => response.json())
            .then((data) => {
                setQG(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
        ////
        fetch(`${process.env.REACT_APP_HOST}/api/theloaidl`)
            .then((response) => response.json())
            .then((data) => {
                setTL(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
        ////
        fetch(`${process.env.REACT_APP_HOST}/api/account`)
            .then((response) => response.json())
            .then((data) => {
                setAcc(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
        fetch(`${process.env.REACT_APP_HOST}/api/khachhang`)
            .then((response) => response.json())
            .then((data) => {
                setKHS(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
        ////
    }, []);
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/get-posts`);
                const data = await response.json();
                setPosts(data); // Lưu danh sách bài viết vào state
            } catch (error) {
                console.error('Lỗi khi tải danh sách bài viết:', error);
            }
        };

        fetchPosts();
    }, []);
    useEffect(() => {
        if (!token) return;
        fetch(`${process.env.REACT_APP_HOST}/api/khachhang/login`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Gửi token trong header
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setKH(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
    }, [token]);
    // Lấy thông tin tài khoản
    useEffect(() => {
        if (!token) return;

        fetch(`${process.env.REACT_APP_HOST}/api/account/login`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                if (!response.ok) throw new Error('Lỗi khi tải dữ liệu tài khoản');
                return response.json();
            })
            .then((data) => {
                setAccountData(data);
            })
            .catch((error) => console.error(`Lỗi:`, error));
    }, [token]);
    return (
        <ApiContext.Provider value={{
            products, quocgia, theloaidl, khachhang, khachhangs, posts, accountData, accounts,
            setTours, setQG, setTL, setKH, setKHS, setPosts, setAccountData, setAcc,
        }}>
            {children}
        </ApiContext.Provider>
    );
};

export default ApiContext;
