import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Sử dụng Navigate từ react-router-dom

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: null };
    }

    // Cập nhật state khi có lỗi xảy ra
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    // Đây là phương thức để bắt lỗi trong quá trình render, lifecycle methods hoặc constructor
    componentDidCatch(error, errorInfo) {
        console.error("Caught an error:", error, errorInfo);  // Đảm bảo log được lỗi
        this.setState({ errorInfo });  // Lưu lại thông tin lỗi nếu cần thiết
    }

    render() {
        if (this.state.hasError) {
            // Điều hướng đến trang lỗi nếu có lỗi
            // return window.location.href = '/error';
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
