
function AccountConnections() {
    return (
        <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-lg">
            <div className="mb-4">
                <button type="button" className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    Connect to <strong>Twitter</strong>
                </button>
            </div>
            <hr className="border-gray-300 my-2" />
            <div className="mb-4">
                <h5 className="text-sm font-medium text-gray-700 mb-2">
                    <a href="" className="float-right text-xs text-gray-500 hover:text-gray-700"><i className="ion ion-md-close"></i> Remove</a>
                    <i className="ion ion-logo-google text-red-500"></i> You are connected to Google:
                </h5>
                <a href="/cdn-cgi/l/email-protection" className="__cf_email__ text-blue-500 hover:underline">[email&#160;protected]</a>
            </div>
            <hr className="border-gray-300 my-2" />
            <div className="mb-4">
                <button type="button" className="w-full py-2 px-4 bg-blue-700 text-white rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    Connect to <strong>Facebook</strong>
                </button>
            </div>
            <hr className="border-gray-300 my-2" />
            <div className="mb-4">
                <button type="button" className="w-full py-2 px-4 bg-pink-500 text-white rounded-md hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-300">
                    Connect to <strong>Instagram</strong>
                </button>
            </div>
        </div>
    );
}

export default AccountConnections;
