import React from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import CartDetails from "../page/cart/CartDetails";
import AccountGeneral from "../component/profile/account-general";
import AccountChangepassword from "../component/profile/account-change-password";
import AccountSociallinks from "../component/profile/account-social-links";
import AccountConnections from "../component/profile/account-connections";
import EditBlog from "../tableBlog/editBlog";
import Orders from "../component/profile/Orders";

const accountLinks = [
    { id: "account-general", label: "Hồ sơ", path: "account-general" },
    { id: "account-change-password", label: "Thay đổi mật khẩu", path: "account-change-password" },
    { id: "account-social-links", label: "Mạng xã hội", path: "account-social-links" },
    { id: "account-blog", label: "Blog cá nhân", path: "account-blog" },
    { id: "account-connections", label: "Liên kết", path: "account-connections" },
    { id: "account-cart", label: "Giỏ hàng", path: "account-cart" },
    { id: "account-orders", label: "Đơn Hàng", path: "account-orders" },
];

const Sidebar = () => {
    const location = useLocation();
    return (
        <div className="w-full p-4 hidden md:flex bg-gray-100">
            <div className="flex w-full">
                {accountLinks.map((link) => (
                    <Link
                        key={link.id}
                        to={link.path}
                        className={`flex items-center justify-center w-full text-left py-2 px-4 rounded-md ${location.pathname === link.path ? "bg-blue-200" : "hover:bg-gray-200"
                            }`}
                    >
                        {link.label}
                    </Link>
                ))}
            </div>
        </div>
    );
};

const Profile = () => {
    return (
        <div className="container mx-auto p-4 py-24 lg:px-10">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="flex flex-col">
                    <Sidebar />
                    <div className="w-full p-4">
                        <Routes>
                            <Route path="/account-general" element={<AccountGeneral />} />
                            <Route path="/account-change-password" element={<AccountChangepassword />} />
                            <Route path="/account-social-links" element={<AccountSociallinks />} />
                            <Route path="/account-blog" element={<EditBlog />} />
                            <Route path="/account-connections" element={<AccountConnections />} />
                            <Route path="/account-cart" element={<CartDetails />} />
                            <Route path="/account-orders" element={<Orders />} />
                            <Route path="*" element={<div>Tab không hợp lệ</div>} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
